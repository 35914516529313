<div class="datepicker-wrapper">
  <label>{{ label }}</label>
  <div class="datepicker-container">
    <mat-icon class="left-icon" svgIcon="calendarCheck"></mat-icon>
    <input
      readonly
      matInput
      [min]="minDate"
      [max]="maxDate"
      class="datepicker-input"
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      [formControl]="control"
      [ngClass]="{
        'has-error' : hasError('required')
      }"
      (click)="picker.open()"
    />
    <mat-datepicker-toggle class="right-icon" [for]="picker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <span *ngIf="hasError('required')" class="error-message">
    Ce champ est requis*
  </span>
</div>