import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColorEditPipe} from 'src/app/pipe/color-edit.pipe';
import {PREVIEW_PLACEHOLDER_MSG} from 'src/environments/globalVariable';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [CommonModule, ColorEditPipe ],
  template: `
    <div class="text-wrapper" [innerHTML]="injectedData$  | colorEdit"></div>
  `,
  styleUrls: ['./preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewComponent {

  @Input('data') injectedData$ : string = PREVIEW_PLACEHOLDER_MSG



}
