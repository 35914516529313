import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, finalize, map, mergeMap, takeUntil } from 'rxjs';
import { CardCreatorService } from 'src/app/services/card-creator.service';
import { ToasterService } from 'src/app/services/toaster.service';
import {
  ExceededData,
  FileViewModel,
} from 'src/app/shared/ui-kit/webiat-file-upload/webiat-file-upload.viewModel';
import { RadioCardViewModel } from 'src/app/shared/ui-kit/webiat-radio-card/webiad-radio-card.viewModel';
import { TOASTER_TYPE } from 'src/app/shared/ui-kit/webiat-toaster/webiat-toaster.viewModel';
import { NewsViewModel } from 'src/app/shared/viewModels/news.viewModel';
import { WebiatFileUploadComponent } from 'src/app/shared/ui-kit/webiat-file-upload/webiat-file-upload.component';
import { TextEditorComponent } from 'src/app/shared/components/text-editor/text-editor.component';
import { CategoryViewModel } from 'src/app/shared/viewModels/category.viewModel';
import { PREVIEW_PLACEHOLDER_MSG } from "src/environments/globalVariable";
import { WebiatRadioCardComponent } from 'src/app/shared/ui-kit/webiat-radio-card/webiat-radio-card.component';
import {CategoryService} from "../../services/category.service";
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ModuleUtil } from 'src/app/services/card-utility';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const ICON_LIST = [
  { name : 'fileIcon' , path :'assets/icons/file-icon.svg'},
  { name : 'imageIcon' , path :'assets/icons/image-icon.svg'},
  { name : 'videoIcon' , path :'assets/icons/video-icon.svg'},
  { name : 'heart' , path :'assets/icons/heart.svg'},
  { name : 'fileIconDisabled' , path :'assets/icons/file-icon-disabled.svg'},
  { name : 'imageIconDisabled' , path :'assets/icons/image-icon-disabled.svg'},
  { name : 'videoIconDisabled' , path :'assets/icons/video-icon-disabled.svg'},
]

function emojisRequiredValidator(router: Router): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const interaction = control.get('interaction')?.value;
    const selectedEmojis = control.get('selectedEmojis')?.value;
    
    const currentPath = router.url;
    const isNotEditCardPage = !currentPath.includes('edit-card');

    if (interaction && (!selectedEmojis || selectedEmojis.length === 0) && isNotEditCardPage) {
      return { emojisRequired: true };
    }
    
    return null;
  };
}


@Component({
  selector: 'app-webiat-card-creator',
  templateUrl: './webiat-card-creator.component.html',
  styleUrls: ['./webiat-card-creator.component.scss'],
})
export class WebiatCardCreatorComponent implements OnInit, OnDestroy {
  @ViewChild(MatSlideToggle) toggle!: MatSlideToggle;
  @ViewChild(MatSlideToggle) toggleComment!: MatSlideToggle;

  @ViewChild(WebiatRadioCardComponent) radioCardComponent!: WebiatRadioCardComponent;
  @ViewChild(WebiatFileUploadComponent, {static: false}) fileUploadComponent:
    | WebiatFileUploadComponent
    | undefined;
  @ViewChild(TextEditorComponent, {static: false}) textEditor:
    | TextEditorComponent
    | undefined;

  private unsubscribe$: Subject<void> = new Subject<void>();

  previewData: string = '';
  previewPlaceholder: string = PREVIEW_PLACEHOLDER_MSG;
  filesData: Array<FileViewModel> = [];
  checked: boolean = true;
  emitedFormValue!: FormGroup;
  commentChecked: boolean = false;


  public allFilesUploaded: FileViewModel[] = [];

  editCard : boolean = false 
  isLoadingCardInfo : boolean = false
  isLoadingMedia : boolean = false

  documentList !: Array<any>
  mediaList !: Array<any>

  selectedImageFiles: Array<any> = []
  selectedDocVideoFiles : Array<any> = []

  isDisabled : boolean = true
  initialLoadedDocNumber = 0
  isFirstTimeValue = false

  cardId !: string
  emojisList = [
    { icon: "assets/icons/like.svg", name: "J’aime", value: "JAIME" },
    { icon: "assets/icons/bravo.svg", name: "Bravo", value: "BRAVO" },
    { icon: "assets/icons/love.svg", name: "J’adore", value: "JADORE" },
    { icon: "assets/icons/support.svg", name: "Soutien", value: "SOUTIEN" },
    { icon: "assets/icons/laugh.svg", name: "Haha", value: "HAHA" },
    { icon: "assets/icons/celebration.svg", name: "Festif", value: "FESTIF" },
    { icon: "assets/icons/instructif.svg", name: "Instructif", value: "INSTRUCTIF" },
    { icon: "assets/icons/sad.svg", name: "Triste", value: "TRISTE" }
  ];
  selectedEmojis: string[] = [];
  highlightedEmoji: string | null = null;

  public loading: boolean = false;
  public currentDate: Date = new Date(Date.now());
  public filesType : string = '';
  public radioButtons: RadioCardViewModel[] = JSON.parse(JSON.stringify(this.cardCreatorService.initialRadioButtons));
  public categoryList!: CategoryViewModel[];
  public formGroup: FormGroup = new FormGroup({});

  toChangeIsDisableValue = false

  isNewCard = true

  previousForm : any 
  private currentDateIntervalId: any;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private cardCreatorService: CardCreatorService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private toasterService: ToasterService,
    private categoryService: CategoryService,
    private route:ActivatedRoute,
    private cd :ChangeDetectorRef,
    private router : Router,
  ) {

    ICON_LIST.forEach( elt =>{
      this.iconRegistry.addSvgIcon( elt.name , this.sanitizer.bypassSecurityTrustResourceUrl(elt.path))
    })

    this.categoryService.queryCategories().subscribe((res) => {
      const excludedCategory = ['JOB', 'Decade_Birthday', 'RECRUT', 'RETIREES'];
      if (res) this.categoryList = res.filter((category) => !excludedCategory.includes(category.code as string));
    });
    
    this.formGroup = this.formBuilder.group(
      {
        titre: ['', Validators.required],
        description: [''],
        category: ['', Validators.required],
        startDate: ['', Validators.required],
        startTime: ['', Validators.required],
        endDate: ['', Validators.required],
        endTime: ['', Validators.required],
        mediaList: [[]],
        documentList: [[]],
        interaction: [this.isOrganoteCategory() ? false : true],
        selectedEmojis: [[]],
        enableComment: [this.commentChecked],
        selectedCommentOption: [''],


      },
      {
        validator: [
          this.checkDates,
          emojisRequiredValidator(this.router),
        ] as AbstractControlOptions
      }
    );

    this.formGroup.get('category')?.valueChanges.subscribe((categoryId: string) => {
      if (this.formGroup.get('category')?.dirty) {
        this.updateInteractionBasedOnCategory(categoryId);
      }
    });

     this.route.url.pipe(
      map((values: UrlSegment[]) => values.filter((value : UrlSegment ) => value.path =='edit-card')),
      map((value: UrlSegment[] )=> value[0]),
      mergeMap(urlSegment => this.route.params.pipe(map(param => {
        return { urlSegment , param}
      })))
    )
    .subscribe((res :any ) =>{
      if(res.urlSegment && res.param.id){
        this.editCard =  this.isDisabled = true
        this.isNewCard = false
        this.cardId= res.param?.id
        this.getCardInfo(this.cardId)
      }
    })
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(
      (value: FormGroup) => {        
        this.emitedFormValue = value;        
        if(this.toChangeIsDisableValue){
          this.isDisabled =  !this.isChanged(this.previousForm , this.formGroup.value)|| this.formGroup.invalid || this.loading;
        }
      }
    );

  this.currentDateIntervalId= setInterval(() => {
      this.currentDate = new Date();
    },1000)
  }

  public onSelectedValueChanged(value: string): void {
    this.filesType = value;
  }


  public onToggleChange(event: any): void {    
    this.formGroup.get('interaction')!.setValue(event.checked);
    this.checked = event.checked;
  }

  public castFormControl(controlName: string): FormControl {
    return this.formGroup.get(controlName) as FormControl;
  }

  public onInputChange(value: string, controlName: string): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(value);
      control.markAsDirty();
      control.updateValueAndValidity();
    }
  }


  public getMinTimeForStartTime(): any {
    const startDateValue: Date = this.formGroup.get('startDate')?.value;
    if (startDateValue) {
      const startDateWithoutTime: Date = this.getDateWithoutTime(startDateValue);
      const currentDateWithoutTime: Date = this.getDateWithoutTime(this.currentDate);
      if (startDateWithoutTime.getTime() === currentDateWithoutTime.getTime()) {
        return this.datePipe.transform(this.currentDate, 'hh:mm a');
      }
    }
  }

  public getMinTimeForEndTime(): any {
    const startDateValue: Date = this.formGroup.get('startDate')?.value;
    const endDateValue: Date = this.formGroup.get('endDate')?.value;
    const currentDateWithoutTime: Date = this.getDateWithoutTime(this.currentDate);

    if (startDateValue) {
      const startDateWithoutTime: Date = this.getDateWithoutTime(startDateValue);
      const endDateValueWithoutTime: Date = this.getDateWithoutTime(endDateValue);
      if (
        startDateWithoutTime.getTime() === endDateValueWithoutTime.getTime()
      ) {
        return this.formGroup.get('startTime')?.value;
      } else if (
        endDateValueWithoutTime.getTime() === currentDateWithoutTime.getTime()
      ) {
        return this.datePipe.transform(this.currentDate, 'hh:mm a');
      }
    } else if (endDateValue) {
      const endDateWithoutTime: Date = this.getDateWithoutTime(endDateValue);

      if (endDateWithoutTime.getTime() === currentDateWithoutTime.getTime()) {
        return this.datePipe.transform(this.currentDate, 'hh:mm a');
      }
    }
  }

  private getDateWithoutTime(dateValue: Date): Date {
    return dateValue ? new Date(dateValue.getFullYear(),dateValue.getMonth(),dateValue.getDate()) : new Date();
  }

  public handleLimitExceeded(isExceeded: ExceededData): void {
    if (isExceeded.exceeded) {
      let message: string;
      if (isExceeded.type === 'image') {
        message = 'Un maximum de 20 images est autorisé';
      } else if (isExceeded.type === 'application') {
        message = 'Un maximum de 2 documents est autorisés';
      } else {
        message = 'Vous êtes autorisés à insérer une seule vidéo';
      }

      this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, message, 10000);
    }
  }

  public handleImageTypeError(isError: boolean): void {
    if (isError) {
      const message = 'Seules les images de format PNG ou JPEG sont autorisés';
      this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, message, 10000);
    }
  }


  public handleFileCombinationViolation(violatedTypes: string[]): void {
    this.radioButtons.forEach((button) => {
      const wasDisabled = button.disabled;
      if (violatedTypes.includes(button.value)) {
        button.disabled = true;
      }

      if (wasDisabled && !violatedTypes.includes(button.value)) {
        button.disabled = false;
      }
    });
  }

  public handleFileChanges(files: FileViewModel[]): void {
    this.allFilesUploaded = files;
    this.separateFiles()
  }

  private separateFiles(): void {
    let mediaList: FileViewModel[] = [];
    let documentList: FileViewModel[] = [];
    this.allFilesUploaded?.forEach((selectedFile) => {
      this.isMedia(selectedFile.file.type) ? mediaList.push(selectedFile) : documentList.push(selectedFile);
    });
    this.formGroup.get('mediaList')?.setValue(mediaList);
    this.formGroup.get('documentList')?.setValue(documentList);

    this.toChangeIsDisableValue = true
    if(!this.isFirstTimeValue){
      this.previousForm =  this.formGroup.value
      this.isFirstTimeValue = !this.isFirstTimeValue
    }
  }

  private isMedia(fileType: string): boolean {
    return fileType.startsWith('video') || fileType.startsWith('image');
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.shouldShowConfirmation()) {
      window.alert('Are you sure you want to reload? Your changes may not be saved.');
      $event.preventDefault();
    }
  }

  private shouldShowConfirmation(): boolean {
    const formValues = this.formGroup.getRawValue();
    return (
      formValues.titre ||
      formValues.category ||
      formValues.startDate ||
      formValues.startTime ||
      formValues.endDate ||
      formValues.endTime ||
      formValues.mediaList ||
      formValues.documentList
    );
  }

  public isFormInvalid(): boolean {
    return this.formGroup.invalid || this.formGroup.pending;
  }

  public onSubmit(): void {
    this.loading = true;
    this.formGroup.get('description')?.setValue(this.previewData);
    const newsData = this.fillNeswViewModel();    
    if (this.formGroup.valid) {     
      this.cardCreatorService
        .persistNews(newsData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            this.loading = false;
            this.resetAllInputs();
            const msgSuccessRequest: string = 'Votre publication a été partagée avec succès.';
            this.toasterService.addToaster(true, TOASTER_TYPE.SUCCESS, msgSuccessRequest, 5000);
            this.navigateToPubList();
          },
          error: (error) => {
            this.loading = false;
            const msgErrorRequest: string = 'Échec de publication . Veuillez vous réessayer.';
            this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, msgErrorRequest, 5000);
          },
          
        });
    } else {
      this.loading = false;
      const msgErrorForm: string = 'Formulaire invalide. Veuillez vérifier les champs.';
      this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, msgErrorForm, 5000);
    }
  }

  updateNews(){
    this.loading = this.isDisabled = true;
    let newsData = this.updateFillNewsViewModel()
    if (this.formGroup.valid) {
      this.cardCreatorService
        .updateNews(newsData , this.cardId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            this.loading =  this.isDisabled = false;
            this.resetAllInputs();
            const msgSuccessRequest: string = 'Votre publication a été modifiée avec succès.';
            this.toasterService.addToaster(true, TOASTER_TYPE.SUCCESS, msgSuccessRequest, 5000);
            this.navigateToPubList();
          },
          error: (error) => {
            this.loading =  this.isDisabled = false;
            const msgErrorRequest: string ='Échec de publication. Veuillez réessayer ultérieurement.';
            this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, msgErrorRequest, 5000);
          },
        });
    } else {
      this.loading =  this.isDisabled = false;
      const msgErrorForm: string = 'Formulaire invalide. Veuillez vérifier les champs.';
      this.toasterService.addToaster(true, TOASTER_TYPE.ERROR,msgErrorForm, 5000);
    }
    return null;
  }

  private checkDates(group: FormGroup): object | null {
    const startDate = group.controls['startDate'].value;
    const endDate = group.controls['endDate'].value;
    if (endDate && startDate && endDate < startDate) {
      return {notValid: true};
    }
    return null;
  }

  private fillNeswViewModel(): NewsViewModel {
    const formValues = this.formGroup.value;  
    const boostComment = this.checked && this.commentChecked && formValues.selectedCommentOption[0] === "A";
    const showComment = this.checked && this.commentChecked && formValues.selectedCommentOption[0] === "B";
  
    return {
      id: '',
      title: formValues.titre,
      description: formValues.description,
      postingStartDate: this.convertDateTime(
        formValues.startDate,
        formValues.startTime
      ),
      postingEndDate: this.convertDateTime(
        formValues.endDate,
        formValues.endTime
      ),
      eventDate: '',
      createdAt:
        this.datePipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss') ?? '',
      assignment: '',
      adviserId: '',
      interaction: formValues.interaction,
      documentList: formValues.documentList,
      mediaList: formValues.mediaList,
      category: this.categoryList.find(el=>el.id===formValues.category) as CategoryViewModel,
      interactionsList: formValues.interaction? formValues.selectedEmojis : [],
      enableComment: formValues.interaction? formValues.enableComment : false,
      boostComment: boostComment,
      showComment: showComment
    };
  }


  updateFillNewsViewModel(){
    let formValues = this.formGroup.value;
    let boostComment = this.checked && this.commentChecked && formValues.selectedCommentOption[0] === "A";
    let showComment = this.checked && this.commentChecked && formValues.selectedCommentOption[0] === "B";
    let enableComment = formValues.enableComment
    if(!formValues.interaction){
      boostComment = showComment = enableComment = false
    }
    let newsData = {
      id: this.cardId,
      title: formValues.titre,
      description: formValues.description,
      postingStartDate: this.convertDateTime(
        formValues.startDate,
        formValues.startTime
      ),
      postingEndDate: this.convertDateTime(
        formValues.endDate,
        formValues.endTime
      ),
      eventDate: '',
      interaction: formValues.interaction,
      documentList: formValues.documentList,
      mediaList: formValues.mediaList,
      category: {
        id: formValues.category,
      },
      enableComment: enableComment,
      boostComment: boostComment,
      showComment: showComment

    };

    return newsData;
  }
  
  private convertDateTime(date: Date, time: string): string {
    let _date: Date = new Date(date)

    return _date.getFullYear().toString()
      + '-' +
      (_date.getMonth() + 1).toLocaleString('fr-FR', {minimumIntegerDigits: 2})
      + '-' +
      _date.getDate().toLocaleString('fr-FR', {minimumIntegerDigits: 2, useGrouping: false})
      + (time.length != 0 ? ' ' + time + ':00' : '')
  }


  private resetAllInputs(): void {
    this.formGroup.reset();
    this.formGroup?.get('category')?.setValue('');
    this.formGroup?.get('interaction')?.setValue(true);
    this.fileUploadComponent?.resetArrays();
    this.textEditor?.resetTextEditor();
    this.radioButtons = JSON.parse(JSON.stringify(this.cardCreatorService.initialRadioButtons));
    this.radioCardComponent.resetRadioGroup();
    this.filesType = ''
    this.checked = true;
    this.commentChecked = false;
    this.formGroup?.get('enableComment')?.setValue(false);
    this.formGroup?.get('selectedCommentOption')?.setValue(['']);
    this.formGroup?.get('selectedEmojis')?.setValue([]);
    this.selectedEmojis = [];
  }


  getCardInfo(id : string){
    this.isLoadingCardInfo = this.isLoadingMedia = true
    this.cardCreatorService.getCardInfoById(id).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(res =>{
      this.documentList= res.documentList
      this.mediaList = res.mediaList
      this.loadDocListDetails(this.documentList)
      this.loadMediaListDetails(this.mediaList)
      this.formGroup.patchValue(
        {
          titre: res.title,
          description: res.description,
          interaction: /^true$/i.test((res.interaction as String).toLowerCase()),
          category: res.category.id,
          startDate: this.parseDate((res.postingStartDateAndTime).split(' ')[0]),
          endDate: this.parseDate((res.postingEndDateAndTime).split(' ')[0]),
          startTime :(res.postingStartDateAndTime).split(' ')[1],
          endTime :(res.postingEndDateAndTime).split(' ')[1],
          enableComment: res.enableComment,
          selectedCommentOption: res.boostComment ? 'A': res.showComment ? 'B' : ['']
        }

      )
      this.checked = /^true$/i.test((res.interaction as String).toLowerCase())      
      this.commentChecked = res.enableComment;
      this.isLoadingCardInfo = false;
      if(res.documentList.length == 0 && res.mediaList.length == 0 ){
        this.isLoadingMedia = false;
      }      
    })
    
  }


  parseDate(dateString: string): Date {
    let parts = dateString.split(/\/|\s|:/);
    return new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
  }

  loadDocListDetails(docList : Array<any>){
    let files : Array<any> = [];
    let reader: FileReader = new FileReader();

      docList.forEach(doc =>{
        this.cardCreatorService.getDocumentByName(doc.name).pipe(
          takeUntil(this.unsubscribe$),
          finalize(()=>{
            files.forEach(file => {
              if(!this.isFileAlreadySelected(file)){
                reader.onload = (e)=>{
                  let fileViewModel: FileViewModel = ModuleUtil.createFileViewModel(file,e);
                  this.selectedDocVideoFiles.push(fileViewModel)
                  this.cd.detectChanges()
                }

                reader.onloadend =() => {
                  this.initialLoadedDocNumber = this.initialLoadedDocNumber + 1
                  this.isLoadingMedia = !(this.documentList.length + this.mediaList.length == this.initialLoadedDocNumber)
                }
                reader.readAsDataURL(file)
              }
            })
          })
        ).subscribe(res =>{
            let file  = new File([res] , doc.name ,{
            type : res.type
          })
          files.push(file)
        })
      })
    
  }

  loadMediaListDetails(mediaList : Array<any>){
    let videoFiles  : Array<any> = [];
    let imageFiles  : Array<any> = [];
    let reader: FileReader = new FileReader();

      let _videoFiles = mediaList?.filter( doc => (doc.type)?.toUpperCase() == 'VIDEO')
      _videoFiles.forEach(video =>{
        this.cardCreatorService.getVideoByPath(video.link).pipe(
          takeUntil(this.unsubscribe$),
          finalize(()=>{
            videoFiles.forEach(_videoFile$ => {
              if(!this.isFileAlreadySelected(_videoFile$)){
                reader.onload = (e)=>{
                  let fileViewModel: FileViewModel = ModuleUtil.createFileViewModel(_videoFile$ , e , 'VIDEO');
                  this.selectedDocVideoFiles.push(fileViewModel)
                  this.cd.detectChanges()
                }
                reader.onloadend =() => {
                  this.initialLoadedDocNumber = this.initialLoadedDocNumber + 1
                  this.isLoadingMedia = !(this.documentList.length + this.mediaList.length == this.initialLoadedDocNumber)                  
                }
                reader.readAsDataURL(_videoFile$)
              }
            })
          })
        ).subscribe(res =>{
          let file  = new File([res] , ModuleUtil.getMediaFileName(video.link) ,{
            type : res.type
          })
          videoFiles.push(file)
        })
      })

      let _imageFiles = mediaList?.filter( doc => (doc.type)?.toUpperCase() == 'PHOTO')
      _imageFiles.forEach(image =>{
        this.cardCreatorService.getImageByPath(image.link).pipe(
          takeUntil(this.unsubscribe$),
          finalize(()=>{
            imageFiles.forEach( _imageFiles$ => {
              if(!this.isFileAlreadySelected(_imageFiles$)){
                reader.onload = (e)=>{
                  let fileViewModel: FileViewModel = ModuleUtil.createFileViewModel(_imageFiles$ , e , 'PHOTO');
                  this.selectedImageFiles.push(fileViewModel)
                  this.cd.detectChanges()
                }
                reader.onloadend =() => {
                  this.initialLoadedDocNumber = this.initialLoadedDocNumber + 1
                  this.isLoadingMedia = !(this.documentList.length + this.mediaList.length == this.initialLoadedDocNumber)
                }
                reader.readAsDataURL(_imageFiles$)
              }
            })
          })
        ).subscribe(res =>{
          let file  = new File([res] , ModuleUtil.getMediaFileName(image.link) ,{
            type : res.type
          })
          imageFiles.push(file)
        })
      })
    
  }

  isFileAlreadySelected(file: File): boolean {
    return (
      [...this.selectedImageFiles,...this.selectedDocVideoFiles].some((selectedFile) => selectedFile.file.name === file.name)
    );
  }


  isChanged(previousForm : any , currentForm : any){ 

    return previousForm?.category != currentForm.category ||
      previousForm?.description != currentForm.description ||
      (previousForm?.endDate as Date).getTime() != (currentForm.endDate as Date).getTime() ||
      previousForm?.endTime != currentForm.endTime ||
      (previousForm?.startDate as Date).getTime() != (currentForm.startDate as Date).getTime() ||
      previousForm?.startTime != currentForm.startTime ||
      previousForm?.titre != currentForm.titre ||
      previousForm?.interaction != currentForm.interaction ||
      !this.compareFilesList(previousForm?.documentList , currentForm?.documentList)||
      !this.compareFilesList(previousForm?.mediaList , currentForm?.mediaList)||
      (previousForm?.enableComment !== currentForm.enableComment)|| 
      ((previousForm?.selectedCommentOption[0] as string) !== (currentForm.selectedCommentOption[0] as string))

  }

  compareFilesList(previousFileList : Array<any> , currentFileList : Array<any>){
    if (previousFileList.length !== currentFileList.length) return false;
    let elements =  new Set([...previousFileList, ...currentFileList]);
    for (const x of elements) {
        const count1 = previousFileList.filter(e => e.file?.name == x.file?.name).length;
        const count2 = currentFileList.filter(e => e.file?.name == x.file?.name).length;
        if (count1 !== count2) return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    clearInterval(this.currentDateIntervalId)
  }

  private isOrganoteCategory(): boolean {
    
    
    const categoryId = this.formGroup.get('category')?.value;
    const category = this.categoryList?.find(cat => cat.id === categoryId);  
    return category?.name === 'Organote' ?? false;
  }
  


  private updateInteractionBasedOnCategory(categoryId: string): void {
    const category = this.categoryList?.find(cat => cat.id === categoryId);
    if (category?.name === 'Organote') {
      this.formGroup.get('interaction')?.setValue(false);
      this.checked = false;
    } else {
      this.formGroup.get('interaction')?.setValue(true);
      this.checked = true;
    }
  }

  onEmojiSelect(event: Event, emoji: string) {
    const checkbox = event.target as HTMLInputElement;
    const selectedEmojis = this.formGroup.get('selectedEmojis')?.value || [];
    this.highlightedEmoji = null;
    
    if (!this.checked) {
      if (selectedEmojis.includes(emoji)){
        checkbox.checked = true;
      }
      else{
        checkbox.checked = false;
      }
      return;
    }
  
    if (checkbox.checked) {
      if (selectedEmojis.length < 5) {
        this.selectedEmojis.push(emoji);
      } else {
        this.highlightedEmoji = emoji;
        setTimeout(() => {
          this.highlightedEmoji = null;
        }, 3000); 
        checkbox.checked = false; 
        const message = 'Vous ne pouvez pas sélectionner plus que 5 réactions.';
        this.toasterService.addToaster(true, TOASTER_TYPE.INFORMATIVE, message, 3000);
      }
    } else {
      const index = selectedEmojis.indexOf(emoji);
      if (index >= 0) {
        this.selectedEmojis.splice(index, 1);
      }
    }
  
    this.formGroup.get('selectedEmojis')?.setValue(this.selectedEmojis);
  }

  public onCommentToggleChange(event: any): void {
    this.formGroup.get('enableComment')!.setValue(event.checked);
    this.commentChecked = event.checked;
  }

  public onCommentSelectedValueChanged(value: string): void {
    this.formGroup.get('selectedCommentOption')!.setValue(value);
    
  }

  navigateToPubList(){
    this.router.navigate(['/content-managment'])
  }

}
