import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ModalService} from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  confirmBtnDisabled: boolean = false;
  @Input() title!: string;
  @Input() textContent!: string;
  @Input() componentProps!: any;
  @Input() submitLabel!: string;
  @Input() cancelLabel!: string;
  @Input() dynamicComponent!: any;
  @Input() isReversed: boolean = false;
  @Input() showCancelButton: boolean = true;
  @Input() btnIcon: string = '';
  @Output() modalEvent = new EventEmitter;
  @Output() modalDismissEvent = new EventEmitter;

  @ViewChild('modalBody', { static: true, read: ViewContainerRef }) modalBody!: ViewContainerRef;

  constructor(private modalService: ModalService,
    public dialog: MatDialog) {

}

  ngOnInit(): void {

    if (this.dynamicComponent) {
      this.modalService.setConfirmButtonDisabled(true);
    } else {
      this.modalService.setConfirmButtonDisabled(false);
    }

    this.modalService.getConfirmButtonDisabled().subscribe(
      (confirmBtnDisabled) => {
        this.confirmBtnDisabled = confirmBtnDisabled;
      }
    )

  }

  modalFunction = (evt: any) => {
    this.modalEvent.emit(evt);
  }

  dismissModal = (evt: any) => {
    this.modalDismissEvent.emit(evt);
  }




}
