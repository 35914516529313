<div class="view-wrapper">

    <div class="container">
        <div class="wrapper">
            <h3 class="title">
                Aperçu de la publication
            </h3>
            <div class="iphone_frame">
                <div class="highlited_stroke"></div>
                <div class="dead_area"></div>
                <div class="iphone_buttons">
                    <div class="left_iphone_button">
                      <div class="options"></div>
                      <div class="button"></div>
                    </div>
                    <div class="right_iphone_button">
                      <div class="options"></div>
                      <div class="button"></div>
                    </div>
                </div>
                <div class="main_screen">
                    <div class="__status_bar">
                        <div class="__left-panel">
                          <div class="__time">{{(currentTime$ | async )| date:'HH:mm' }}</div>
                        </div>
                        <div class="dynamic_island"></div>
                        <div class="__right-panel">
                          <div class="__wifi">
                              <img src = "../../../../assets/icons/mobile-signal-icon.svg" alt="battery-icon"/>
                              <img src = "../../../../assets/icons/wifi-icon.svg" alt="battery-icon"/>
                              <img src = "../../../../assets/icons/battery-icon.svg" alt="battery-icon"/>
                          </div>
                        </div>
                    </div>
                    <div class="header_location">
                        <div class="acceuil_title">
                            <img src="../../../../assets/images/preview-logo.png" alt="logo">
                        </div>
                        <div class="notif-faq-container">

                            <div class="notification-wrapper">
                                <img src="../../../../assets/images/notification-icon2.png" alt="notification">
                            </div>
                            <div class="faq_title">
                                <img src="../../../../assets/images/FAQ-icon.png" alt="FAQ">
                            </div>
                        </div>
                    </div>
                    <div class="view-container">
                        <ng-content></ng-content>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</div>