import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {REQUESTS_BASE_URL} from "../../environments/environment";
import {Observable} from "rxjs/internal/Observable";
import {CategoryViewModel} from "../shared/viewModels/category.viewModel";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
  ) {
  }

  queryCategories():Observable<CategoryViewModel[]> {
    return this.http.get<CategoryViewModel[]>(
      `${REQUESTS_BASE_URL}/api/home/client-api/category/all`
    )
  }
}
