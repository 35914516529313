<div class="logout-wrapper">
  <mat-icon svgIcon="orangeLogout"></mat-icon>
  <div class="logout-text-container">
  <span>Déconnexion</span>
  <label>Êtes-vous sûr de vouloir vous déconnecter ?</label>
</div>
  <div class="logout-buttons">
    <button class="stroked-btn" (click)="closeDialog()">Annuler</button>
    <button mat-raised-button class="raised-btn" (click)="logOut()">Se déconnecter</button>
  </div>
</div>
