import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private activeItem$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setActiveItem(routerLink: string): void {
    this.activeItem$.next(routerLink);
  }

  getActiveItem(): Observable<string> {
    return this.activeItem$.asObservable();
  }
}
