import {Component, Inject} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationCreatorService} from 'src/app/services/notification-creator.service';
import {ToasterService} from 'src/app/services/toaster.service';
import {TOASTER_TYPE} from 'src/app/shared/ui-kit/webiat-toaster/webiat-toaster.viewModel';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {catchError} from "rxjs";


@Component({
  selector: 'app-informative-modal',
  templateUrl: './informative-modal.component.html',
  styleUrls: ['./informative-modal.component.scss']
})
export class InformativeModalComponent {

  dialogRef !: MatDialogRef<InformativeModalComponent>;
  public loading: boolean = false;


  constructor(
    private notificationCreatorService: NotificationCreatorService,
    private toasterService: ToasterService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'sendIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/send-icon.svg')
    );
  }


  public confirmNotfiCreation(): void {
    if (this.data.componentName === 'nonStandard') {
      this.loading = true;
      const payload = {
        title: this.data.formContent.get('titre').value,
        description: this.data.formContent.get('notificationContent').value,
        sendDate: this.combineDateAndTime(this.data.formContent.get('sendingDate').value, this.data.formContent.get('sendingTime').value),
        notifTypeId: this.data.formContent.get('selectedNotificationType').value,
        users: this.checkForNon(this.data.formContent.get('destinator').value),
      };
      this.notificationCreatorService.confirmNewNONStandardizeNotifCreation(payload)
        .pipe(
          catchError((error) => {
            this.loading = false;
            this.dialog.closeAll();
            this.router.navigate(['/collaborator-list/notification'])
            const msgErrorRequest: string = 'Échec d’envoi. Veuillez réessayer ultérieurement.';
            this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, msgErrorRequest, 5000);
            return error;
          })
        )
        .subscribe(
          (response: any) => {
            if (response?.status === 200) {
              this.loading = false;
              this.dialog.closeAll();
              this.router.navigate(['/collaborator-list/notification'])
              const msgSuccessRequest: string = 'Notification envoyée avec succès.';
              this.toasterService.addToaster(true, TOASTER_TYPE.SUCCESS, msgSuccessRequest, 5000);
            }

          }
        );

    } else if (this.data.componentName === 'Standard') {
      this.loading = true;
      this.notificationCreatorService.confirmNewStandardizeNotifCreation().pipe(
        catchError((error) => {
          this.loading = false;
          this.dialog.closeAll();
          this.router.navigate(['/collaborator-list/notification'])
          const msgErrorRequest: string = 'Échec d’envoi. Veuillez réessayer ultérieurement.';
          this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, msgErrorRequest, 5000);
          return error;
        })
      )
        .subscribe(
          (response: any) => {
            if (response?.status === 200) {
              this.loading = false;
              this.dialog.closeAll();
              this.router.navigate(['/collaborator-list/notification'])
              const msgSuccessRequest: string = 'Notification envoyée avec succès.';
              this.toasterService.addToaster(true, TOASTER_TYPE.SUCCESS, msgSuccessRequest, 5000);
            }

          }
        );
    }
  }


  public combineDateAndTime(dateString: string, timeString: string): string {

    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');

    const [hours, minutes] = timeString.split(':');

    return `${year}-${month}-${day} ${hours}:${minutes}:00`;
  }


  checkForNon(arr: string[]): string[] {
    if (JSON.stringify(arr) === JSON.stringify(["none"])) {
      return [];
    } else {
      return arr;
    }
  }

}


