import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-webiat-datepicker',
  templateUrl: './webiat-datepicker.component.html',
  styleUrls: ['./webiat-datepicker.component.scss'],
})
export class WebiatDatepickerComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
  @Input() control: FormControl = new FormControl();

  public value!: any;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'calendarCheck',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/calendar-check.svg'
      )
    );
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe((value: Date) => {
      if (value) {
        this.value = value.toLocaleDateString('fr-Fr');
      }
    });
  }

  public hasError(errorType: string): boolean {
    return this.control.hasError(errorType) && this.control.touched;
  }
}
