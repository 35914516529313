import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-webiat-dashboard-content',
  templateUrl: './webiat-dashboard-content.component.html',
  styleUrls: ['./webiat-dashboard-content.component.scss'],
})
export class WebiatDashboardContentComponent implements OnInit {
  public agentFirstName!: string;
  constructor(
    private keycloakAngular: KeycloakService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'handEmoji',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hand-emoji.svg')
    );
  }

  async ngOnInit(): Promise<void> {
    const isAuthenticated = await this.keycloakAngular.isLoggedIn();

    if (isAuthenticated) {
      // Get the Keycloak instance
      const keycloakInstance = this.keycloakAngular.getKeycloakInstance();

      // Check if idTokenParsed is defined
      if ( keycloakInstance?.idTokenParsed) {

        this.keycloakAngular.loadUserProfile().then(profile => {

          if (profile?.firstName) {
            this.agentFirstName = profile?.firstName;
          }

        })
      }
    }

  }
}
