import {Component, OnInit,} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationCreatorService} from 'src/app/services/notification-creator.service';
import {ModalService} from 'src/app/services/modal.service';

@Component({
  selector: 'app-standard-notification',
  templateUrl: './standard-notification.component.html',
  styleUrls: ['./standard-notification.component.scss']
})
export class StandardNotificationComponent implements OnInit {

  selectedNotificationType: string = '';
  notificationTypes: any[] = [];
  checked: boolean = false;
  selectedOption: string = 'inferior';
  daysNumber: { supInfValue: string, betweenStartValue: string, betweenEndValue: string } = {
    supInfValue: '',
    betweenStartValue: '',
    betweenEndValue: ''
  };

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private notificationCreatorService: NotificationCreatorService,
    private modalService: ModalService,
  ) {
    iconRegistry.addSvgIcon(
      'superiorIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/superior-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'inferiorIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/inferior-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'betweenIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/between-icon.svg')
    );
  }

  ngOnInit(): void {
    this.loadNotificationTypes();
  }

  loadNotificationTypes(): void {
    this.notificationCreatorService.getCategoryNotification('STANDARDIZED').subscribe(types => {
      this.notificationTypes = types;
    });
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  public onToggleChange(event: any): void {
    this.checked = event.checked;
    this.changeConfirmButtonState()

  }
  onSelectedOptionChange(selectedOption: string): void {
    this.selectedOption = selectedOption;
    this.daysNumber = {
      supInfValue: '',
      betweenStartValue: '',
      betweenEndValue: ''
    };
    this.changeConfirmButtonState();
  }

  onDaysNumberValueChange(): void {
    this.changeConfirmButtonState();
  }

  changeConfirmButtonState(): void {
    if (!this.checked) {
      let result=this.selectedNotificationType === '';
      if (!result){
        this.notificationCreatorService.updateparams(this.selectedNotificationType);
      }
      this.modalService.setConfirmButtonDisabled(result);

    }
    else {
      if (this.selectedOption === 'superior' || this.selectedOption === 'inferior') {
        let result=this.daysNumber.supInfValue === '' ||
        this.selectedNotificationType === '' ||
        this.daysNumber.supInfValue === null
        this.modalService.setConfirmButtonDisabled(result);
        if (!result){
          this.notificationCreatorService.updateparams(this.selectedNotificationType, this.selectedOption==='superior'? ">" : "<" , parseInt(this.daysNumber.supInfValue) , 0 );
        }

      } else if (this.selectedOption === 'between') {
        let result= this.daysNumber.betweenStartValue === '' ||
        this.daysNumber.betweenEndValue === '' ||
        this.selectedNotificationType === '' ||
        this.daysNumber.betweenStartValue === null ||
        this.daysNumber.betweenEndValue === null
        this.modalService.setConfirmButtonDisabled(result);
        if (!result){

          this.notificationCreatorService.updateparams(this.selectedNotificationType, "<=>" , parseInt(this.daysNumber.betweenStartValue) , parseInt(this.daysNumber.betweenEndValue) );
        }

      }
    }
  }

}


