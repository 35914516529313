<div class="webiat-toaster-container">
  <div
    [class]="'webiat-toaster-card ' + toaster.toaster_type"
    *ngFor="let toaster of toastersStack$ | async"
  >
    <div class="toaster-content">
      <div class="toaster-text-content">
        
        <img *ngIf="toaster.toaster_type === 'success'" src="assets/icons/success.svg" alt="">
        <img *ngIf="toaster.toaster_type === 'error'" src="assets/icons/error.svg" alt="">
        <img *ngIf="toaster.toaster_type === 'info'" src="assets/icons/alert-fill.svg" alt="">
         <span class="toaster-message">{{ toaster.toaster_message }}</span>
           </div>
      <mat-icon
        class="toaster-icon-close"
        [svgIcon]="toaster.toaster_type === 'success' ? 'close' : 
                   toaster.toaster_type === 'error' ? 'red_close' : 
                   toaster.toaster_type === 'info' ? 'brown_close' : ''"
        (click)="dismiss(toaster)"
      ></mat-icon>
    </div>
  </div>
</div>