
<mat-radio-group [(ngModel)]="selectedValue" class="radio-flex">
    <label
      for="{{ item.value }}"
      class="radio-card-wrapper"
      *ngFor="let item of data"
      [ngClass]="{
        'is-checked': item.value === selectedValue,
        'disabled-card': item.disabled || !commentChecked
      }"
      (click)="item.disabled || !commentChecked ? null : onCardClick(item.value)"
    >
      <div class="icon-section">
        <img *ngIf="item.value === 'A'" src="../../../../assets/images/thumbUp.png" alt="Icon 1" class="icon">
        <img *ngIf="item.value === 'B'" src="../../../../assets/images/textChat.png" alt="Icon 2" class="icon">

      </div>
      <div class="body-content">
        <div class="title">{{ item.title }}</div>
        <div class="content-text">{{ item.description }}</div>
      </div>
      <div class="checkbox-section">
        <mat-radio-button
        id="{{ item.value }}"
        [value]="item.value"
        [disabled]="item.value !== selectedValue || !commentChecked"
        >
      </mat-radio-button>
      <img *ngIf="item.value === selectedValue" src="../../../../assets/icons/check.svg" alt="icon">
      </div>
    </label>
</mat-radio-group>
  