<div class="modal-content">
    <div class="modal-header">
      <div class="header-content">
        <h4 class="modal-title" mat-dialog-title> La liste des collaborateurs </h4>
        <h5 class="modal-description"> {{ selectedDestinatorsNumber <= 1? selectedDestinatorsNumber + ' collaborateur sélectionné' : selectedDestinatorsNumber + ' collaborateurs sélectionnés'}} </h5>
      </div>
      <div class="modal-icon-close">
        <img (click)="onCancelClick()" src="./assets/icons/x-close.svg" alt="modal" />
      </div>
    </div>
    <div [ngClass]="{'body-wrapper': true, 'modal-body': true}">

        <div class="collab-list-wrapper">
            <div class="collabs">
                <div class="spiner-wrapper" *ngIf="areCollabLoading">
                    <mat-spinner [diameter]="24"></mat-spinner>
                </div>
                <div class="list collab-wrapper" *ngFor="let collaborator of collaboratorsList">
                    <div class="collab-info-wrapper">
                        <div class="image-wrapper">
                            <app-collab-profile-image [id]="collaborator?.id" [firstName]="collaborator?.firstname" [lastName]="collaborator?.lastname" style="font-size: 11px;"></app-collab-profile-image>
                        </div>
                        <div class="collab-info">
                            <div class="name">{{collaborator?.name}}
                            </div>
                            <div class="department">
                                {{collaborator?.assignment}}</div>
                        </div>
                    </div>
                    <button (click)="toggleSelection(collaborator.id)"
                        [ngClass]="{
                        'action-btn': CollabsToRemoveList.includes(collaborator.id),
                        'unselect-btn': !CollabsToRemoveList.includes(collaborator.id)
                    }">
                    <span [ngClass]="{
                            'btn-content': CollabsToRemoveList.includes(collaborator.id),
                            'unselect-btn-content': !CollabsToRemoveList.includes(collaborator.id)
                    }">
                        {{ CollabsToRemoveList.includes(collaborator.id) ? 'Sélectionner' : 'Déselectionner' }}
                    </span>
                    </button>
                </div>
            </div>
        </div>

      </div>
    <div class="modal-footer">
        <button mat-button class="validate-btn" [ngClass]="{'diasbled-validate-btn': CollabsToRemoveList.length == 0 }" (click)="onApproveClick()">Valider</button>
    </div>
  </div>
