<div class="timepicker-wrapper">
  <label *ngIf="label">{{ label }}</label>
  <div class="timepicker-container">
    <mat-icon class="left-icon" svgIcon="clock"></mat-icon>
    <input
      matInput
      readonly
      [min]="minTime"
      [max]="maxTime"
      [value]="time"
      class="timepicker-input"
      [placeholder]="placeholder"
      [ngxTimepicker]="picker"
      [format]="24"
      [formControl]="control"
      [ngClass]="{
        'has-error': hasError('required')
      }"
    />
    <mat-icon class="right-icon" (click)="openTimePicker()">keyboard_arrow_down</mat-icon>
  </div>
  <span *ngIf="hasError('required')" class="error-message">
    Ce champ est requis*
  </span>
</div>

<ngx-material-timepicker
  [timepickerClass]="'custome-class'"
  #picker
  (opened)="onPickerOpened()"
  (closed)="onPickerClosed()"

  [cancelBtnTmpl]="cancelBtn"
  [confirmBtnTmpl]="confirmBtn">
></ngx-material-timepicker>

  <ng-template #cancelBtn>
    <button  mat-button >Annuler</button>
  </ng-template>
  <ng-template #confirmBtn>
    <button  mat-button >Valider</button>
  </ng-template>
