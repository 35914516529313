

<div  class=" custom-modal-content" >
    <div class=" custom-modal-body">
        <img src="../../../../assets/images/confirm-notif-creation.svg" alt="">
        <h1 class=" custom-modal-title" id="exampleModalLabel">Confirmation de l’envoi de la notification</h1>
      <p class="custom-modal-text">Une fois envoyée, la notification sera visible par les utilisateurs concernés. Confirmez vous l'envoi ?</p>
    </div>


    <div class=" custom-modal-footer">
            <button  mat-dialog-close class=" outlined-btn custom-text-annuler">Annuler</button>
          
            <button (click)="confirmNotfiCreation()" [disabled]="loading" [class]="{'filled-btn': true, 'disabled': loading }" class="mat-button  custom-text-confirm" >
              <div class="btn-icon" >
                <mat-icon class="envoyer-icon" svgIcon="sendIcon"></mat-icon>
              </div>
          
              <span>Envoyer</span>
              <mat-icon *ngIf="loading" class="spinner"><mat-spinner mode="indeterminate" diameter="16"> </mat-spinner></mat-icon> 
            </button>
    </div>   

  </div>



