import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {REQUESTS_BASE_URL} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicationsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getWebPublications(from: number, size: number) {
    return this.http.get(
      `${REQUESTS_BASE_URL}/api/home/client-api/news-web?from=${from}&size=${size}`
    )
  }


  createNews(newBody: Object) {
    return this.http.post(
      `${REQUESTS_BASE_URL}/api/home/client-api/create-news`,
      newBody,
      {
        observe: 'response'
      }
    )
  }

  public deletePublicationById(pubId: string) {
    return this.http.delete<any>(`${REQUESTS_BASE_URL}/api/home/client-api/deleteNewsWeb/${pubId}`,
      {observe: 'response'});

  }


  getSearchedNewsList(from: number, size: number, key: string){
    let encodedKey = encodeURIComponent(key)
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/home/client-api/news-web/search-news?from=${from}&size=${size}&key=${encodedKey}`)
  }
}
