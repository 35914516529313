import {Component} from '@angular/core';

@Component({
  selector: 'app-webiat-access-denied',
  templateUrl: './webiat-access-denied.component.html',
  styleUrls: ['./webiat-access-denied.component.scss'],
})
export class WebiatAccessDeniedComponent  {

}
