<div class="standardNotif-modalBody" *ngIf="notificationTypes.length > 0; else loading">
    <div class="notification-type">
        <label class="modalBody-text text-title"> Type de notification </label>
        <select (change)="changeConfirmButtonState()" class="select-content" [(ngModel)]="selectedNotificationType" name="notificationType">
          <option class="modalBody-text text-content" value="" disabled selected hidden> Sélectionnez le type de la notification </option>
          <option *ngFor="let type of notificationTypes" class="modalBody-text text-content" [value]="type.id">{{ type.name }}</option>
        </select>
    </div>
    <div class="message-content">
        <label class="modalBody-text text-title"> Corps de la notification </label>
        <div class="text-wrapper">
           <span class="modalBody-text text-content"> Votre nombre de jours de congés à consommer obligatoirement avant le 31/12/{{ getCurrentYear() }} est X jours. Merci de planifier et saisir vos congés puis les faire valider par votre manager.</span>
        </div>
    </div>
    <div class="notification-condition">
      <div class="toggle-wrapper">
        <label for="toggle" class="modalBody-text text-title">Ajouter une condition à votre notification</label>
        <mat-slide-toggle
          id="toggle"
          [checked]= false
          (change)="onToggleChange($event)"
      ></mat-slide-toggle>
      </div>
      <div *ngIf="checked" class="conditions-wrapper">
        <hr class="separation-line">
        <div class="condition-options-wrapper">
            <div class="option"  [ngClass]="{'selected-option': selectedOption === 'superior'}" (click)=" onSelectedOptionChange('superior') ">
              <mat-icon class="option-icon" svgIcon="superiorIcon"></mat-icon>
              <span> Supérieur à </span>
            </div>
            <div class="option"  [ngClass]="{'selected-option': selectedOption === 'inferior'}" (click)=" onSelectedOptionChange('inferior') ">
              <mat-icon class="option-icon" svgIcon="inferiorIcon"></mat-icon>
              <span> Inférieur à </span>
            </div>
            <div class="option"  [ngClass]="{'selected-option': selectedOption === 'between'}" (click)=" onSelectedOptionChange('between') ">
              <mat-icon svgIcon="betweenIcon"></mat-icon>
              <span> Entre </span>
            </div>
        </div>
        <div class="condition-specification-wrapper"> 
          <select class="select-content" name="notification-condition" id="condition">
            <option value="days-nbr"> Nombre de jours </option>
          </select>
          <div class="operator">
            <mat-icon class="option-icon" *ngIf="selectedOption === 'superior'" svgIcon="superiorIcon"></mat-icon>
            <mat-icon class="option-icon" *ngIf="selectedOption === 'inferior'" svgIcon="inferiorIcon"></mat-icon>
          </div>
          <div class="inf-sup-input-number" *ngIf="selectedOption === 'superior' || selectedOption === 'inferior'">
            <input [(ngModel)]="daysNumber.supInfValue" class="input-number" type="number" (input)="onDaysNumberValueChange()">
          </div>
          <div class="between-input-numbers" *ngIf="selectedOption === 'between'">
            <input [(ngModel)]="daysNumber.betweenStartValue" class="input-number between-input" type="number" (input)="onDaysNumberValueChange()">
            <mat-icon *ngIf="selectedOption === 'between'" svgIcon="betweenIcon"></mat-icon>
            <input [(ngModel)]="daysNumber.betweenEndValue" class="input-number between-input" type="number" (input)="onDaysNumberValueChange()">
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #loading>
  <div class="spiner-wrapper">
    <mat-spinner [diameter]="48"> </mat-spinner> 
  </div>
</ng-template>