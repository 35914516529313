import { CommonModule } from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-webiat-drop-down',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './webiat-drop-down.component.html',
  styleUrls: ['./webiat-drop-down.component.scss'],
})
export class WebiatDropDownComponent {
  @Input() selectedItem !: string
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() data: any;
  @Input() control: FormControl = new FormControl();


  public hasError(errorType: string): boolean {
    return this.control.hasError(errorType) && this.control.touched;
  }
}
