  <div class="name_category">
    {{ categoryTitle }}
  </div>

  <div class="spinner-wrapper" *ngIf="displayLoading && isLoadingDetails">
    <mat-spinner [diameter]="48"> </mat-spinner>
    <span class="search-loading-text">En cours de recherche…</span>
  </div>
  <div class="carte_wrapper" *ngIf="!displayLoading || !isLoadingDetails">
    <div class="carte_header">
      <div
        [ngClass]="
          iconNameImage.length != 0 ? 'icon_carte_shadow' : 'icon_carte'
        "
      >
        <img
          src="../../../../assets/images/{{ iconNameImage }}"
          alt="icon"
          *ngIf="iconNameImage.length != 0; else empty"
        />
        <ng-template #empty>
          <img src="../../../../assets/images/empty_category.png" alt="icon" />
        </ng-template>
      </div>
      <div class="carte_category">
        <div class="goupe_name">
          {{
            formValue?.titre && formValue?.titre.length != 0
              ? formValue?.titre
              : "Titre de la publication"
          }}
        </div>
        <div class="publish_time">
          Le {{ formatDateString(formValue?.startDate) || "JJ MMM AAAA" }}
        </div>
      </div>
    </div>
    <div class="text-container">
      <ng-content></ng-content>
    </div>

    <div class="spinner" *ngIf="displayLoading && isLoadingMedia">
      <mat-spinner [diameter]="48"> </mat-spinner>
      <span class="search-loading-text">En cours de recherche…</span>
    </div>

    <div class="media-wapper">
      <div class="img-video-wrapper">
        <figure class="image-container" *ngIf="imageIsExist">
          <div class="image-counter">1 / {{ imageList.length }}</div>
          <img class="image_src" src="{{ imageList[0].url }}" alt="iamge" />
        </figure>
        <video
          controls
          autoplay
          [muted]="'muted'"
          loop
          class="video-container"
          *ngIf="videoIsExist"
        >
          <source src="{{ videoList[0].url }}" type="video/mp4" />
        </video>
      </div>
      <div class="doc_wrapper" *ngIf="docIsExist">
        <div *ngFor="let doc of docList" class="wrapper_element">
          <hr />
          <div class="file_element">
            <div
              [ngClass]="
                getFileNameType(doc.file.name)[1] == 'pdf'
                  ? 'icon_doc_pdf'
                  : 'icon_doc'
              "
            >
              <img
                src="../../../../assets/images/{{
                  getFileNameType(doc.file.name)[1] == 'pdf'
                    ? 'pdf_doc_icon.png'
                    : 'doc_icon.png'
                }}"
                alt="doc-icon"
              />
            </div>
            <div class="name_type_doc">
              <div class="name_doc">
                {{ getFileNameType(doc.file.name)[0] }}
              </div>
              <div class="type_size_doc">
                Type: .{{ getFileNameType(doc.file.name)[1] }} /
                {{ formatBytes(doc?.file?.size ?? 0) }}
              </div>
            </div>
            <div class="download_icon">
              <img
                src="../../../../assets/images/download-icon.png"
                alt="download"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="reaction_wrapper" *ngIf="reactionStatus"></div>
  </div>
  <div class="carte_wrapper2"></div>
