<div class="editor-wrapper" [ngClass]="{ 'pass-char-number': limitCharacterNumber - textLength <= 0,stylingClass }" >

    <div class="editor" #refEditor [formGroup]="form"  >
        <angular-editor class="text-editor" formControlName="editorContent" [config]="editorConfig"
        (keydown)="handleKeyDown($event)" (paste)="handlePaste($event)"
        >
            <ng-template #customButtons let-executeCommandFn="executeCommandFn" class="angular-editor-toolbar-set">
                <ae-toolbar-set>
                <ae-button iconClass="fa color-icon-button-orange" title="Orange-color"
                            (buttonClick)="executeCommandFn('foreColor', '#FB8108')">
                </ae-button>
                <ae-button iconClass="fa color-icon-button-black" title="Black-color"
                            (buttonClick)="executeCommandFn('foreColor', '#000000')">
                </ae-button>
                <ae-button iconClass="arabic-icon" title="Arabic-font"
                            (buttonClick)="executeCommandFn('fontName', 'Arabic')">
                </ae-button>
                <ae-button iconClass="french-icon" title="French-font"
                            (buttonClick)="executeCommandFn('fontName', 'NeurialGrotesk-Medium')">
                </ae-button>
                </ae-toolbar-set>
            </ng-template>
        </angular-editor>
    </div>

    <span *ngIf="limitCharacterNumber - textLength > 0; else other_content" class="character-count">
        {{ limitCharacterNumber - textLength +" "+limitText}}
      </span>
      <ng-template #other_content>
        <span class="character-count">Vous avez atteint la limite des caractères autorisés </span>
      </ng-template>
</div>
