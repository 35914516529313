<div class="modal-content">
    <div class="modal-header">
      <div class="header-content">
        <h4 class="modal-title" mat-dialog-title> {{title}} </h4>
        <h5 class="modal-description"> {{textContent}} </h5>
      </div>
      <div class="modal-icon-close">
        <img (click)="dismissModal($event)" src="../../../../assets/icons/x-close.svg" alt="modal" />
      </div>
    </div>
    <div [ngClass]="{'body-wrapper': true, 'modal-body': dynamicComponent}">
      <div #modalBody>
      </div>
    </div>
    <div class="modal-footer">
  
      <button *ngIf="! (!isReversed && !showCancelButton)" [class]="{'outlined-btn': true, 'disabled': isReversed && confirmBtnDisabled, 'single-btn': !showCancelButton }"
        (click)="isReversed ? modalFunction($event) : dismissModal($event)" [disabled]="isReversed && confirmBtnDisabled">
        {{isReversed ? submitLabel : cancelLabel}}
      </button>

      <button *ngIf="! (isReversed && !showCancelButton)" [class]="{'filled-btn': true, 'disabled': !isReversed && confirmBtnDisabled, 'single-btn': !showCancelButton }"
      (click)="isReversed ? dismissModal($event) : modalFunction($event)"
      [disabled]="!isReversed && confirmBtnDisabled">
      <div class="btn-icon" *ngIf="btnIcon.length > 0">
        <img  [src]="btnIcon" alt="Button Icon" />
      </div>
      {{isReversed ? cancelLabel : submitLabel}}
    </button>
  
    </div>
  </div>