import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REQUESTS_BASE_URL } from 'src/environments/environment';
import {BehaviorSubject, pluck} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  private commentDataSubject = new BehaviorSubject<{ commentsId: string[], subComments: { commentId: string, subcommentList: string[] }[] }>({
    commentsId: [],
    subComments: []
  });
  commentData$ = this.commentDataSubject.asObservable();
  _reloadCommentsList = new BehaviorSubject<string>("");
  reloadCommentsList$ = this._reloadCommentsList.asObservable();

  constructor(
    private http: HttpClient,
  ) { }


  getCommentsList(from: number, size: number){
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/home/client-api/v1/all-comments?from=${from}&size=${size}`)
  }

  getCountNotVerifiedComments(){
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/home/client-api/notVerifiedComment`)
  }

  getCommentsListByNewsId(newsId : string , from: number, size: number){
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/home/client-api/v1/web/news-comments/${newsId}?from=${from}&size=${size}`)
  }

  getNewCommentsList(commentId:string, from: number, size: number){
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/home/client-api/v1/web/notVerifiedCommentOrSubComment?commentId=${commentId}&from=${from}&size=${size}`)
  }

  verfiyComment(id: string, type: string) {
    const requestBody = {
      commentId: id,       
      commentType: type  
    };
    
    return this.http.put<any>( `${REQUESTS_BASE_URL}/api/home/client-api/v1/web/markCommentOrSubCommentVerified`, requestBody);
  }

  updateCommentData(data: { commentsId: string[], subComments: { commentId: string, subcommentList: string[] }[] }) {
    this.commentDataSubject.next(data);
  }
  getSubCommentsListByCommentId(commentId: number, from: number, size: number){
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/home/client-api/v1/news-sub-comment/${commentId}?from=${from}&size=${size}`)
  }


  deleteComment(commentId:string){
    return this.http.delete<any>(`${REQUESTS_BASE_URL}/api/home/client-api/home/delete/comment/${commentId}`)
  }
  deleteSubComment(subCommentId:string){
    return this.http.delete<any>(`${REQUESTS_BASE_URL}/api/home/client-api/v1/news-sub-comment/${subCommentId}`)
  }

}
