<div class="files-uploader-container">
  <div class="files-uploader-wrapper" [ngClass]="{ 'files-uploader-Error': !isAuthorized || !isWithin }"
    *ngIf="filesType.length > 0 && isShown">
    <input type="file" #fileDropRef id="fileDropRef" [multiple]="multiple" (change)="onSelectFiles($event)"
      [accept]="getAcceptType()" />
    <mat-icon [svgIcon]="!isAuthorized || !isWithin ? 'fileUploaderError' : 'fileUploader'"></mat-icon>
    <label *ngIf="!isShown" for="fileDropRef">Cliquer ici pour parcourir et télécharger</label>
    <div *ngIf="isShown">
      <ng-content></ng-content>

    </div>
  </div>

  <div *ngIf="selectedImageFiles.length" class="image-list">
    <div *ngFor="let selectedFile of selectedImageFiles; let i = index">
      <div class="image-prview-wrapper">
        <img class="image-preview" [src]="selectedFile.url" alt="{{ selectedFile.file.name }}" (click)="clickOnImage(i)"
          [ngClass]="{ 'image-selected': i === selectedIndex }" />
        <button *ngIf="(i === selectedIndex)" mat-icon-button (click)="removeFile(i, true)"
          class="delete-img-btn" [disabled]="isTreaty">
          <mat-icon svgIcon="trash"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="selectedDocVideoFiles.length"
    [ngClass]="{'file-list':isShown, 'file-details-list': !isShown, 'expanded': isTreaty || isTreatCompleted }">
    <div *ngFor="let selectedFile of selectedDocVideoFiles; let i = index">

      <div class="single-file"
        [ngClass]="{ 'load-completed': selectedFile?.progress === 100 && isShown, 'file-details':!isShown, 'file-details-completed': isTreatCompleted, 'file-details-treaty': isTreaty }">
        <div class="file-info-row">
          <div [ngClass]="{'file-name':isShown, 'file-name-ingest':!isShown}">{{
            truncateFileName(selectedFile?.file?.name, 20) }}

          </div>

          <div class="delete-doc-btn" *ngIf="!isTreaty" (click)="removeFile(i, false)">
            <mat-icon svgIcon="trashOutline"></mat-icon>
          </div>
        </div>
        <span [ngClass]="{'file-size':!isShown || isTreatCompleted}">{{ formatOctets(selectedFile?.file?.size!)
          }}</span>
        <div class="progess-doc-upload file-info" *ngIf="isShown ">
          <mat-progress-bar class="custom-progress-bar" mode="determinate"
            value="{{ selectedFile?.progress }}"></mat-progress-bar>
          {{ selectedFile?.progress }}%
        </div>
        <div class="container-bar-progress">
          <div class="progress-container" *ngIf="isTreaty || isTreatCompleted ">
            <div [ngClass]="{'progress-bar':isTreaty, 'progress-bar-completed':isTreatCompleted}">

            </div>
          </div>
          <div class="progress-size" *ngIf="isTreatCompleted"> {{ selectedFile?.progress }}% </div>
        </div>
      </div>
    </div>
  </div>
</div>