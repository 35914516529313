import { HttpClient, HttpHeaders, HttpParams,  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, mergeMap, of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { REQUESTS_BASE_URL } from 'src/environments/environment';
import { NewsViewModel } from '../shared/viewModels/news.viewModel';
import { GUID } from '../shared/helpers';
import { environment } from 'src/environments/environment';
import { RadioCardViewModel } from '../shared/ui-kit/webiat-radio-card/webiad-radio-card.viewModel';

@Injectable({
  providedIn: 'root',
})
export class CardCreatorService {
  public initialRadioButtons: RadioCardViewModel[] = [
    {
      value: 'image',
      ActiveIcon: 'imageIcon',
      disabledIcon: 'imageIconDisabled',
      title: 'Images',
      description: 'Ici vous pouvez télécharger jusqu’à 20 images',
      disabled: false,
    },
    {
      value: 'video',
      ActiveIcon: 'videoIcon',
      disabledIcon: 'videoIconDisabled',
      title: 'Vidéo',
      description: 'Ici vous pouvez télécharger une vidéo',
      disabled: false,
    },
    {
      value: 'doc',
      ActiveIcon: 'fileIcon',
      disabledIcon: 'fileIconDisabled',
      title: 'Documents',
      description:
        'ici vous pouvez télécharger jusqu’au deux documents ( de type PDF et/ou Word )',
      disabled: false,
    },
  ];
  constructor(
    private http: HttpClient,
  ) { }

  public getCategoryList(): Observable<any> {
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/home/client-api/category/all`);
  }

  private prepareFormData(formData: NewsViewModel): Observable<any[]> {
    const { mediaList, documentList } = formData;
    const uploadObservables: Observable<any>[] = [];

    if (mediaList?.length > 0) {
      mediaList.forEach((media: any) => {
        media.code = GUID.newGUID();
        uploadObservables.push(this.uploadFile(media.file));
      });
    }
    if (documentList?.length > 0) {
      documentList.forEach((doc: any, index: number) => {
        doc.code = GUID.newGUID();
        doc.priority = index + 1;
        uploadObservables.push(this.uploadFile(doc.file));
      });
    }

    if (uploadObservables.length === 0) {
      return of([]);
    }
    return forkJoin(uploadObservables);
  }

  private prepareDataUploadWithDelete(formData: NewsViewModel): Observable<any[]> {
    let { mediaList, documentList } = formData;
    let uploadObservables: Observable<any>[] = [];

    if (mediaList?.length > 0) {
      mediaList.forEach((media: any) => {
        media.code = GUID.newGUID();        
        uploadObservables.push(this.uploadFileWithDelete(media.file));
      });
    }
    if (documentList?.length > 0) {
      documentList.forEach((doc: any, index: number) => {
        doc.code = GUID.newGUID();
        doc.priority = index + 1;
        uploadObservables.push(this.uploadFileWithDelete(doc.file));
      });
    }

    if (uploadObservables.length === 0) {
      return of([]);
    }
    return forkJoin(uploadObservables);
  }

  uploadFileWithDelete(file: File): Observable<any>{
    let formData = new FormData();
    formData.append('file', file);
    formData.append('blobName', file.name)

    return this.http.post(`${REQUESTS_BASE_URL}/api/file-manager/client-api/v1/uploadFilesWithDelete`, formData)
      .pipe(
        catchError((error) => {
          console.error('File upload error:', error);
          return throwError(() => error);
        })
      );
  }


  updateNews(newsData: any , newsId : string){
    return this.prepareDataUploadWithDelete(newsData).pipe(
      mergeMap((responses: any[]) => {
        return this.http
          .put<any>(
            `${REQUESTS_BASE_URL}/api/home/client-api/update-news`,
            {
              id: newsId,
              title: newsData.title,
              description: newsData.description,
              coverPicture: newsData.mediaList[0] ? 'Generic/home/' + newsData.mediaList[0]?.file.name : null,
              postingStartDate: newsData.postingStartDate,
              postingEndDate: newsData.postingEndDate,
              eventDate: newsData.postingStartDate,
              interaction: newsData.interaction,
              category: newsData.category,

              documentList: newsData.documentList.map((doc : any) => ({
                name: doc.file.name,
                code: doc.code,
                priority: doc.priority,
              })),
              mediaList: newsData.mediaList.map((media: any) => ({
                name: media.file.name,
                code: media.code,
                type: media.mediaType,
              })),
              enableComment : newsData.enableComment,
              boostComment: newsData.boostComment,
              showComment: newsData.showComment
            }
          )
          .pipe(
            catchError((error) => {
              console.error('News update error:', error);
              return throwError(() => error);
            })
          );
      })
    )
  }


  private uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    let environmentSuffix = 'webiat';
    if (environment.REDIRECTION_URI === 'https://www.biatrhdigital.com') {
      environmentSuffix += '-prd';
    } else if (environment.REDIRECTION_URI === 'https://www.biatdigitalrh.com') {
      environmentSuffix += '-uat';
    }

    return this.http
      .post(
        `${REQUESTS_BASE_URL}/api/file-manager/client-api/v1/files/${environmentSuffix}/${file.name}`,
        formData)
      .pipe(
        catchError((error) => {
          console.error('File upload error:', error);
          return throwError(() => error);
        })
      );
  }

  public persistNews(newsData: NewsViewModel): Observable<any> {
    return this.prepareFormData(newsData).pipe(
      mergeMap(() => {
        return this.http
          .post<any>(
            `${REQUESTS_BASE_URL}/api/home/client-api/create-news`,
            {
              title: newsData.title,
              description: newsData.description,
              coverPicture: newsData.mediaList[0] ? 'Generic/home/' + newsData.mediaList[0]?.file.name : null,
              postingStartDate: newsData.postingStartDate,
              postingEndDate: newsData.postingEndDate,
              eventDate: newsData.postingStartDate,
              createdAt: newsData.createdAt,
              assignment: null,
              adviserId: null,
              interaction: newsData.interaction,
              category: newsData.category,

              documentList: newsData.documentList.map((doc) => ({
                name: doc.file.name,
                code: doc.code,
                priority: doc.priority,
              })),
              mediaList: newsData.mediaList.map((media) => ({
                name: media.file.name,
                code: media.code,
                type: media.mediaType,
              })),
              interactionsList: newsData.interactionsList,
              enableComment : newsData.enableComment,
              boostComment: newsData.boostComment,
              showComment: newsData.showComment
            })
          .pipe(
            catchError((error) => {
              console.error('News creation error:', error);
              return throwError(() => error);
            })
          );
      })
    );
  }

  getCardInfoById(id : any ){
    return this.http
    .get<any>(
      `${REQUESTS_BASE_URL}/api/home/client-api/news/${id}`,
    )
  }

  getDocumentByName(docName : string){
    return this.http.get(`${REQUESTS_BASE_URL}/api/file-manager/client-api/v1/downloadFile/${docName}/NEWS`, 
      { 
        responseType: 'blob',
      }
    )   
  }

  getVideoByPath(path : string){
    let params = new HttpParams().set('name', path);
    return this.http.get(`${REQUESTS_BASE_URL}/api/content-service/client-api/files/videos`, 
      { 
        responseType: 'blob',
        params : params
      }
    )   
  }

  getImageByPath(pathName : string){
    let params = new HttpParams().set('filename', pathName);
    return this.http.get(`${REQUESTS_BASE_URL}/api/content-service/client-api/files`, 
      { 
        responseType: 'blob',
        params : params
      }
    )   
  }
}
