import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-collab-profile-image',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngIf="isLoaded">
      <img [src]="imageSafeUrl" alt="avatar" *ngIf="imageSafeUrl">
      <div *ngIf="!imageSafeUrl" [ngStyle]="{'background-color': backgroundColor}" class="initials">
          <span [ngStyle]="{'color': letterColor}">{{ initials }}</span>
      </div>
    </ng-container>
  `,
  styleUrls: ['./collab-profile-image.component.scss']
})
export class CollabProfileImageComponent implements OnInit, OnDestroy , OnChanges{

  @Input() id ?: string
  @Input() firstName?: string;
  @Input() lastName?: string;
  imageSafeUrl: any;
  photoSubscription !: Subscription;
  initials: string = '';
  backgroundColor: string = '';
  letterColor: string = '';
  isLoaded: boolean = false;

  private listBackgroundColors = [
    "#E0EEF5", "#E0E9F5", "#FBEFDB", "#FAE5DB", "#FDF5D9", "#EDEAE9"
  ];
  private listLetterColors = [
    "#2B6788", "#254676", "#BB8217", "#833711", "#AD8D0B", "#5A5149"
  ];

  constructor(
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {  
    if (this.id) {
      this.getUserImage(this.id)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id'] && changes['id'].currentValue !== changes['id'].previousValue) {
      this.isLoaded = false;
      if (this.photoSubscription) {
        this.photoSubscription.unsubscribe();
      }
      if (this.id) {
        this.getUserImage(this.id);
      } else {
        this.imageSafeUrl = null;
        this.isLoaded = true; 
      }
    }
  }


  getUserImage(id: string) {
    this.photoSubscription = this.userService.getUserPhoto(id).subscribe(
      (imageBlob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          this.imageSafeUrl = reader.result?.toString();
          this.isLoaded = true;
        };
      },
      (error) => {
        this.imageSafeUrl = null; 
        this.setInitialsAndColors();
        this.isLoaded = true;
      }
    );
  }
  
  setInitialsAndColors() {
    if (this.firstName && this.lastName) {
      this.initials = `${this.firstName[0].toUpperCase()}${this.lastName[0].toUpperCase()}`;
      const colorIndex = (this.calculateAsciiSum(this.firstName) + this.calculateAsciiSum(this.lastName)) % 6;
      this.backgroundColor = this.listBackgroundColors[colorIndex];
      this.letterColor = this.listLetterColors[colorIndex];
    }
  }

  calculateAsciiSum(name: string): number {
    return name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  }

  ngOnDestroy(): void {
    this.photoSubscription?.unsubscribe()
  }
}
