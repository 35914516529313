export interface WebiatToaster {
    display_toaster: boolean;
    toaster_message: string;
    toaster_type: TOASTER_TYPE | undefined;
    toaster_timeout: number;
}

export enum TOASTER_TYPE {
    SUCCESS = 'success',
    ERROR = 'error',
    INFORMATIVE = 'info'
}