import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TOASTER_TYPE, WebiatToaster,} from '../shared/ui-kit/webiat-toaster/webiat-toaster.viewModel';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {

  private toastersStack: WebiatToaster[] = [];
  private readonly defaultTimeout = 10000;

  private toastersStackSubject: BehaviorSubject<WebiatToaster[]> =
    new BehaviorSubject<WebiatToaster[]>(this.toastersStack);

  private toasterSubscriptions: Map<WebiatToaster, NodeJS.Timeout> = new Map<
    WebiatToaster,
    NodeJS.Timeout
  >();

  public getToasters(): Observable<WebiatToaster[]> {
    return this.toastersStackSubject.asObservable();
  }

  public addToaster(
    display: boolean,
    toasterType: TOASTER_TYPE,
    message: string,
    timeout: number = this.defaultTimeout
  ): void {
    const toaster: WebiatToaster = {
      display_toaster: display,
      toaster_message: message,
      toaster_type: toasterType,
      toaster_timeout: timeout,
    };
    const existingToaster = this.toastersStack.find(
      (t) =>
        t.display_toaster === toaster.display_toaster &&
        t.toaster_message === toaster.toaster_message &&
        t.toaster_type === toaster.toaster_type &&
        t.toaster_timeout === toaster.toaster_timeout
    );

    if (!existingToaster) {
      this.toastersStack.unshift(toaster);
      this.toastersStackSubject.next([...this.toastersStack]);

      const timer = setTimeout(() => {
        this.closeToaster(toaster);
        this.toasterSubscriptions.delete(toaster);
      }, timeout);

      this.toasterSubscriptions.set(toaster, timer);
    }
  }

  public closeToaster(toaster: WebiatToaster): void {
    const index = this.toastersStack.indexOf(toaster);
    if (index !== -1) {
      this.toastersStack.splice(index, 1);
      this.toastersStackSubject.next([...this.toastersStack]);
    }

    const timer = this.toasterSubscriptions.get(toaster);
    if (timer) {
      clearTimeout(timer);
      this.toasterSubscriptions.delete(toaster);
    }
  }
}
