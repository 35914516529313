import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RadioCardViewModel } from './webiad-radio-card.viewModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-webiat-radio-card',
  templateUrl: './webiat-radio-card.component.html',
  styleUrls: ['./webiat-radio-card.component.scss'],
})
export class WebiatRadioCardComponent implements OnInit{
  @Input() data!: RadioCardViewModel[];
  @Output() selectedValueChanged = new EventEmitter<string>();
  public selectedValue: string = '';

  constructor(
    iconRegistry: MatIconRegistry, 
    sanitizer: DomSanitizer, 
    private route: ActivatedRoute,
    ) {
    iconRegistry.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg')
    );
  }

  ngOnInit(): void {
    // on applique cette condition temporairement pour s'assurer que qq soit la creation des cartes ou la creation des notifications fonctionnent normalement. cuz if we remove it the continue btns in the notif modal will allways be activated
    this.route.url.subscribe(segments => {
      if (segments.some(segment => segment.path === 'news-card')) {
        this.selectedValueChanged.emit(this.selectedValue);
      }
    });

  }

  public onCardClick(value: string): void {
    this.selectedValue = value === this.selectedValue ? '' : value;
    this.selectedValueChanged.emit(this.selectedValue);
  }

  public resetRadioGroup(): void {
    this.selectedValue = '';
  }
}
