import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {WebiatFileUploadComponent} from '../ui-kit/webiat-file-upload/webiat-file-upload.component';
import {ModalService} from 'src/app/services/modal.service';
import {CollaboratorService} from 'src/app/services/collaborator.service';
import {ToasterService} from 'src/app/services/toaster.service';
import {TOASTER_TYPE} from '../ui-kit/webiat-toaster/webiat-toaster.viewModel';
import {UPLOAD_TREATED_LINE_MSG} from 'src/environments/globalVariable';
import {saveAs} from 'file-saver';

export const treatedStatus = {
  success : "success",
  error : "error",
  partial : "partial"
}
@Component({
  selector: 'app-webiat-modal',
  templateUrl: './webiatmodal.component.html',
  styleUrls: ['./webiatmodal.component.scss'],
})
export class WebiatmodalComponent {
  @Input() target : any ;
  @Output() getCollabListResponse : EventEmitter<any> = new EventEmitter<any>()
  @Output() UploadedFile : EventEmitter<any> = new EventEmitter<any>()
  successImportMessage = 'Liste importée avec succès'
  public filesType: string = 'ingest';
  public isUploaded: boolean = false;
  public fileUploaded: any;
  public isTreaty: boolean = false;
  public isTreatCompleted: boolean = false;

  status ?: string
  successLine !: number
  failedLine !:number
  failedFile !: string
  treatedStatus = treatedStatus

  @ViewChild(WebiatFileUploadComponent, { static: false }) fileUploadComponent:
    | WebiatFileUploadComponent
    | undefined;

  constructor(
    public dialogRef: MatDialogRef<WebiatmodalComponent, any>,
    private modalService: ModalService,
    private collaboratorService: CollaboratorService,
    private toasterService :ToasterService,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }


  public getFile(isUp: boolean): void {
    this.isUploaded = isUp;
  }

  public handleFileChange(file: File): void {
    this.fileUploaded = file;
  }

  onSubmit() {
    this.isTreaty = true;
    this.modalService.updateModalSize('480px', '292px');
    if (this.dialogRef) {
      this.dialogRef.disableClose = true;
      this.collaboratorService
        .traitementLeaveBalance(this.fileUploaded , this.target)
          .subscribe((res) => {
            if(res.userList){
              this.getCollabListResponse.emit(res)
              this.UploadedFile.emit(this.fileUploaded)
            }
            this.successLine= res?.successfulIngestion
            this.failedLine= res?.failedIngestion
            this.failedFile = res?.failedFilePath
            this.checkResponseType(res?.successfulIngestion, res?.failedIngestion)

            this.isTreatCompleted = true;
            this.isTreaty = false;
          });
    }
  }

  checkResponseType(succInge : number , failInge : number){
    if(succInge == 0 ){
      this.status = treatedStatus.error
    }else if(failInge == 0 ){
      this.status = treatedStatus.success
    }else{
      this.status = treatedStatus.partial
    }

  }

  updateSizeModel(){
    this.dialogRef.updateSize('480px')
    this.dialogRef.disableClose = true
  }

  formatBytes(bytes : number ,decimals? : number ) {
    if(bytes == 0) return '0 Octet';
    let k = 1024,
        dm = decimals ?? 2,
        sizes = ['Octet', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  showSuccessToaster(){
    this.closeDialog();
    this.toasterService.addToaster(true, TOASTER_TYPE.SUCCESS, this.successImportMessage, 5000);

  }

  retryImportation(){
    this.isTreatCompleted= false;
    this.isTreaty = false;
    this.isUploaded= false
    this.dialogRef.updateSize('480px' ,'322px')

  }


  showTreatedLineMsg(lineNumber : number, type: 'error' | 'success'){
    return (lineNumber > 1 ?  UPLOAD_TREATED_LINE_MSG[type].multiple : UPLOAD_TREATED_LINE_MSG[type].single)
    .replace(/\{_lineNumber\}/, lineNumber.toString())
    .split('{_split}')
  }


  download(){
    this.collaboratorService.downloadFile(this.failedFile).subscribe((res : any) =>{
      let data: Blob = new Blob([res], {
        type: 'text/csv;charset=utf-8'
      });
      saveAs(data, "fichier-erroné.csv");
    })
  }
}
