<div class="access-denied-container">
  <div class="access-denied-left-side">
    <div class="left-side-content">
      <div class="left-side-content-text-dot">
        <span class="dot"></span>
        <label>Accès refusé</label>
      </div>
      <p>
        Désolé, vous ne disposez pas des autorisations nécessaires pour accéder
        à ce site. Veuillez contacter l'administrateur du site pour obtenir
        l'accès approprié.
      </p>
    </div>
    <div class="left-side-footer">
      <span>WeBIAT-2024</span>
      <label>webiat.contact@biat.com.tn</label>
    </div>
  </div>
  <div class="access-denied-right-side">
    <img src="assets/images/RightSideBackground.png" alt="background" />
  </div>
</div>
