import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {REQUESTS_BASE_URL} from 'src/environments/environment';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class UserProfilService {
  userId: string = '';



  constructor(
    private http: HttpClient,
  ) { }

  public setUserId(userId: string) {
    this.userId = userId;
    localStorage.setItem('userId', userId); // Stocker dans le localStorage
  }

  public getUserId(): string {
    if (this.userId!=='') {
      return this.userId;
    }else {
      return localStorage.getItem('userId') || '';
    }
  }

  public clearUserId() {
    this.userId = '';
    localStorage.removeItem('userId'); // Effacer du localStorage
  }


  public getCollaboratorById(userId: string) {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/info/${userId}`);
  }

  getCollabInfoCvById(id : string){
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/cv-by-id`,
      { params });
  }

  public getCollabtrainingById(userId: string) {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/training/client-api/v1/training/user/${userId}`);
  }

  updateImage(file: File, id: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.put(`${REQUESTS_BASE_URL}/api/file-manager/client-api/v1/updateImage/${id}`, formData, {
      responseType: 'text'
    })
  }

  getAndSearchtrainings(from: number, size: number, type: string, key: string): Observable<any> {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/training/client-api/v1/training/web/search/type?from=${from}&size=${size}&type=${type}&key=${key}`)
 }

 createTrainingById(body: any){
return this.http.post<any>(`${REQUESTS_BASE_URL}/api/training/client-api/v1/TrainingAssignment/create/`,
  body, 
  {
      observe: 'response'
  });}

  getDeplomaById(id : string){
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/diploma/${id}`)
  }

  getBankExperienceById(id : string){
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/bank-experience/${id}`)
  }
  getPreviousExperienceById(id : string){
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/previous-experience/${id}`)
  }

  updateDiploma(payload: any) {
    return this.http.put<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/update/diploma`, payload);
  }

  createDiploma(payload: any) {
    return this.http.post<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/profile/diploma`, payload);
  }

  updatePreviousExperience(payload: any) {
    return this.http.put<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/update/previous-experience`, payload);
  }
  updateBankExperience(payload: any) {
    return this.http.put<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/update/bank-experience`, payload);
  }
  createPreviousExperience(payload: any) {
    return this.http.post<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/profile/previous-experience`, payload);
  }
  createBankExperience(payload: any) {
    return this.http.post<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/profile/bank-experience`, payload);
  }

  public DeleteCvById(expType: string, expId: string) {
    return this.http.delete<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/cv/${expId}?experience=${expType}`,
      {observe: 'response'});

  }

}
