<ng-container *ngIf="!isTreatCompleted">

  <span mat-dialog-title *ngIf="target==='import-sold'">Le fichier de solde des congés</span>
  <span mat-dialog-title *ngIf="target!=='import-sold'">La liste des collaborateurs</span>
  <div class="sub-container">
    <span>Parcourir et importer votre fichier</span>
  </div>
  <div class="modal-details">
    <app-webiat-file-upload [filesType]="filesType" [isTreaty]="isTreaty" [multiple]="false"
      [isTreatCompleted]="isTreatCompleted" (getFileResult)="getFile($event)" (fileChange)="handleFileChange($event)">
      <div class="content-text-wrapper">
        <label for="fileDropRef">Cliquer ici pour télécharger</label><br>
        <span class="label">Un seul fichier de type CSV peut être importé</span>
      </div>
    </app-webiat-file-upload>
  </div>
  <div mat-dialog-actions
    [ngClass]="{'action-class': isUploaded , 'action-treat-mode':isTreaty || isTreatCompleted && isUploaded}">
    <button mat-stroked-button *ngIf="!isTreatCompleted" (click)="closeDialog()" class="cancel-modal-button"
      [disabled]="isTreaty">
      Annuler
    </button>
    <button mat-stroked-button type="submit" *ngIf="!isTreatCompleted" (click)="onSubmit()"
      [ngClass]="{'submit-modal-button':isUploaded , 'submit-modal-button-uploaded': !isUploaded || isTreaty}"
      [disabled]="!isUploaded || isTreaty">
      Commencer le traitement
    </button>
    <button mat-stroked-button type="submit" *ngIf="isTreatCompleted" class="rapport-button"
      [disabled]="!isUploaded || isTreaty" (click)="closeDialog()">
      Voir rapport
    </button>
  </div>
</ng-container>


<ng-container *ngIf="isTreatCompleted">

  {{updateSizeModel()}}
  <div class="header-section" id="response">
    <div class="header-wrapper">

      <ng-container *ngTemplateOutlet="header"></ng-container>

    </div>
    <div class="button-close"  (click)="dialogRef.close()">
      <img src="../../../assets/images/close-icon.png" alt="fermer">
    </div>
  </div>
  
  <div class="content-details-section">
    <div class="content-wrapper">
      <div class="content-info">
        <div class="file-wrapper">
          <div class="file_element">
            <div class="file-icon">
                <img src="../../../assets/images/csv-file-icon.png" alt="doc-icon"> 
            </div>
            <div class="name_type_doc">
              <div class="name_doc">
                {{fileUploaded?.name}}
              </div>
              <div class="type_size_doc">
                Taille de fichier: {{formatBytes(fileUploaded?.size)}}
              </div>
            </div>
            <ng-container *ngTemplateOutlet="icon_status"></ng-container>
        </div>
        </div>
          <ng-container *ngTemplateOutlet="message_body"></ng-container>
        </div>
    </div>
  </div>
  
  <div class="footer-section">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
  </div>
</ng-container>




<ng-template #header>
  <ng-container *ngIf="status === treatedStatus.success">
    <div class="header-title">Liste traitée avec succès !</div>
    <div class="header-subtitle">Votre fichier a été traité avec succès.</div>
  </ng-container>
  <ng-container  *ngIf="status === treatedStatus.error">
    <div class="header-title">Échec de traitement !</div>
    <div class="header-subtitle">Le traitement du fichier a abouti à un échec.</div>
  </ng-container>
  <ng-container  *ngIf="status === treatedStatus.partial">
    <div class="header-title">Liste partiellement traitée avec succès !</div>
    <div class="header-subtitle">Votre fichier a été partiellement traitée.</div>
  </ng-container>
</ng-template>

<ng-template #icon_status>
  <div class="check_icon"  *ngIf="status === treatedStatus.success">
    <img src="../../../assets/images/check-icon.png" alt="check">
  </div>

  <div class="error_icon" *ngIf="status === treatedStatus.error">
    <img src="../../../assets/images/error-icon.png" alt="check">
  </div>

  <div class="check_icon" *ngIf="status === treatedStatus.partial">
    <img src="../../../assets/images/green-check-icon.png" alt="check">
  </div>
</ng-template>

<ng-template #message_body>
  <div class="response-details" *ngIf="status === treatedStatus.success">
    <div class="response-wrapper" >
      <div class="check_icon">
        <img src="../../../assets/images/green-check-icon.png" alt="check">
      </div>
      <div class="response-text">
        <span>{{showTreatedLineMsg(successLine,'success')[0]}}</span>{{showTreatedLineMsg(successLine,'success')[1]}}
      </div>
    </div>
  </div>

  <div class="response-details" *ngIf="status === treatedStatus.error">
    <div class="response-wrapper error" >
      <div class="info_icon">
        <img src="../../../assets/images/info-icon.png" alt="check">
      </div>
      <div class="response-text">
        <span>{{showTreatedLineMsg(failedLine,'error')[0]}}</span>{{showTreatedLineMsg(failedLine,'error')[1]}}
      </div>
    </div>
  </div>

  <ng-container *ngIf="status === treatedStatus.partial">
    <div class="response-partial">
      <div class="response-partial-elt">
        <div class="check_icon">
          <img src="../../../assets/images/green-check-icon.png" alt="check">
        </div>
        <div class="response-text">
          <span>{{showTreatedLineMsg(successLine,'success')[0]}}</span>{{showTreatedLineMsg(successLine,'success')[1]}}
        </div>
      </div>
      <div class="response-partial-elt">
        <div class="warning_icon">
          <img src="../../../assets/images/warning-icon.png" alt="warning">
        </div>
        <div class="response-text">
          <span>{{showTreatedLineMsg(failedLine,'error')[0]}}</span>{{showTreatedLineMsg(failedLine,'error')[1]}}
        </div>
      </div>
    </div>
  </ng-container>

</ng-template>


<ng-template #footer>
  <div class="footer-wrapper" *ngIf="status === treatedStatus.error">
    <div class="footer-button default" (click)="dialogRef.close()">Annuler</div>
    <div class="footer-button primary" (click)="retryImportation()">Réessayer</div>
  </div>

  <div class="footer-wrapper" *ngIf="status === treatedStatus.success">
    <div class="footer-button primary" (click)="showSuccessToaster()">Continuer</div>
  </div>

  <div class="footer-wrapper" *ngIf="status === treatedStatus.partial">
    <div class="footer-button default" (click)="download()">Télécharger le rapport</div>
    <div class="footer-button primary" (click)="showSuccessToaster()">Continuer</div>
  </div>
</ng-template>