export const iconCategoryList   =  [
    {
        id:'a6aad32b-b083-40db-b3ab-e7da228dfd51',
        name:'actualite-icon.png',
        category:'Actualité',
        code:'NEWS'
    },
    {
        id:'4303f338-904e-4619-92b4-255418e5f429',
        name:'devRh-icon.png',
        category:'Dév RH & Com Interne',
        code:'MSG_DEV'
    },
    {
        id:'386a0e3b-9294-11ee-91ff-1b5248736ab8',
        name:'organote-icon.png',
        category:'Organote',
        code:'ORGANOTE'
    },
    {
        id:'5752254d-5539-4d6d-b32a-62b3406f8a36',
        name:'RH-icon.png',
        category:'Ressources Humaines BIAT',
        code:'MSG_RH'
    },
    {
        id:'c6b73a60-bf3b-4ab2-9fb2-2088eb0b1d0b',
        name:'sante-icon.png',
        category:'Santé',
        code:'HEALTH'
    },
    {
        id:'8dd74853-3875-4d17-95f7-afe268015dfb',
        name:'biat-logo.png',
        category:'Retraite',
        code:'RETIREES'
    },
    {
        id:'1845fb30-a550-4edd-8364-06acdd56e03d',
        name:'biat-logo.png',
        category:'Bourse interne',
        code:'JOB'
    },
    {
        id:'3ebf05b9-77cd-11ee-91ff-1b5248736ab8',
        name:'biat-logo.png',
        category:'Ancienneté',
        code:'Decade_Birthday'
    },
    {
        id:'fe25e5be-4b69-4601-b97f-21f05c2072a4',
        name:'biat-logo.png',
        category:'Recrutement',
        code:'RECRUT'
    }
]

export const PREVIEW_PLACEHOLDER_MSG = "Insérez votre texte ici…"

export const UPLOAD_TREATED_LINE_MSG ={
    success:{
        single:'{_lineNumber} ligne {_split} a été traitée avec succès.',
        multiple:'{_lineNumber} lignes {_split} ont été traitées avec succès.'
    },
    error:{
        single:"{_lineNumber} ligne {_split} n'a pas pu être traitée.",
        multiple:"{_lineNumber} lignes {_split} n'ont pas pu être traitées."
    }
}
