import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-select-comment',
  standalone:true,
  templateUrl: './select-comment.component.html',
  styleUrls: ['./select-comment.component.scss'],
  imports: [
    CommonModule,
    MatCardModule ,
    MatIconModule, 
    MatCheckboxModule ,
    MatButtonModule,
    MatRadioModule,
    FormsModule,
    
  ],
})
export class SelectCommentComponent implements OnInit {
  public selectedValue: string = '';
  @Input() commentChecked: boolean = true;
  @Input() valueFromParent!: string;
  @Output() selectedValueChanged = new EventEmitter<any>();

  data = [
    {
      value: "A",
      title: "Booster les commentaires",
      description: "Le clic sur le bouton \"J'aime\" affiche un champ de commentaire",
      disabled: false
    },
    {
      value: "B",
      title: "Afficher un commentaire",
      description: "Le clic sur la section \"Afficher un commentaire\" permet de voir le commentaire le plus aimé",
      disabled: false
    }
  ];

  constructor(
    iconRegistry: MatIconRegistry, 
    sanitizer: DomSanitizer, 
    private route: ActivatedRoute,
    ) {
    iconRegistry.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg')
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['valueFromParent']) {
      this.selectedValue = changes['valueFromParent'].currentValue; 
    }
  }

  ngOnInit(): void {
 
     if (this.valueFromParent)
      this.selectedValue = this.valueFromParent;
    else
    this.selectedValue = '';
  
  }

  onCardClick(value: string) {
    this.selectedValue = value === this.selectedValue ? '' : value;
    this.selectedValueChanged.emit([this.selectedValue]);
}

  public resetRadioGroup(): void {
    this.selectedValue = '';
  }

}



