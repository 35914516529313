import { AngularEditorConfig } from "@kolkov/angular-editor"
import { PREVIEW_PLACEHOLDER_MSG } from "src/environments/globalVariable";

export const EDITOR_CONFIG: AngularEditorConfig = {
      editable: true,
      spellcheck: false,
      height: '15rem',
      minHeight: '15rem',
      maxHeight: '15rem',
      width: 'auto',
      minWidth: '0',
      translate: 'no',
      enableToolbar: true,
      showToolbar: true,
      placeholder: PREVIEW_PLACEHOLDER_MSG,
      defaultParagraphSeparator: 'p',

    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
            [
              'strikeThrough',
              'subscript',
              'superscript',
              'justifyFull',
              'justifyCenter',
              'indent',
              'outdent',
              'fontName',
              'italic',
              'heading',
            ],
            [
            'fontSize',
            'unlink',
            'insertImage',
            'insertVideo',
            'textColor',
            'backgroundColor',
            'toggleEditorMode',
            'customClasses',
          ],
    ]
};