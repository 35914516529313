import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard implements CanActivate {
  constructor(
    protected override router: Router,
    protected override keycloakAngular: KeycloakService
  ) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.keycloakAngular.login({
          redirectUri: environment.REDIRECTION_URI,
        }); // Deployed App
        return;
      }

      //access the required role from route.data
      const requiredRoles: string[] = route.data['roles'];
      let granted: boolean = false;

      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true; // No specific role required
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }

      if (this.roles.includes('ROLE_ADMIN') && state.url === '/access-denied') {
        this.router.navigate(['/content-managment']);
      }

      if (granted === false && state.url !== '/access-denied') {
        this.router.navigate(['/access-denied']);
      }
      resolve(granted);
    });
  }
}
