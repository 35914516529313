import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { interval, map, Observable, startWith } from 'rxjs';
import { FileViewModel } from '../webiat-file-upload/webiat-file-upload.viewModel';
import { iconCategoryList } from 'src/environments/globalVariable';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-device-simulator',
  templateUrl: './device-simulator.component.html',
  styleUrls: ['./device-simulator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class DeviceSimulatorComponent {
  currentTime$: Observable<Date> = interval(1000).pipe(
    startWith(0),
    map(() => new Date())
  );

  formatDateString(dateString: string): string {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    const formatDate: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return date.toLocaleDateString('fr-FR', formatDate);
  }
}
