import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, catchError, throwError} from 'rxjs';
import {REQUESTS_BASE_URL} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorService {

  constructor(
    private http : HttpClient,
  ) {}

  getCollaboratorList(from: number, size : number){
    return this.http.get(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v2/user-profile/users/random?from=${from}&size=${size}`)
  }

  getFiltredCollaboratorList(from: number, size: number, key: string) {
    const encodedKey = encodeURIComponent(key)
    return this.http.get(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/search-web?from=${from}&size=${size}&key=${encodedKey}`)
  }

  getSearchedCollabList(from: number, size: number, key: string) {
    const encodedKey = encodeURIComponent(key)
    return this.http.get(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/search-users?from=${from}&size=${size}&key=${encodedKey}`)
  }


  public getAffectationList(): Observable<any> {
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/assignment/active`);
  }

  public getJobList(): Observable<any> {
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/job/active`);
  }

  public getRoleList(): Observable<any> {
    return this.http.get<any>(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/role/active`);
  }

  getFilterCollaboratorList(from: number, size : number, assignmentList : string, roleList : string, jobList : string ){
    return this.http.get(
      `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/filter-web?from=${from}&size=${size}&assignmentList=${assignmentList}&roleList=${roleList}&jobList=${jobList}`)
  }

  traitementLeaveBalance(file: File , target:string): Observable<any>{
    const formData = new FormData();
     formData.append('file', file);
     if(target =="import-sold"){

       return this.http.post(
         `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/web/ingestion/leave`,
         formData,
       )
       .pipe(
         catchError((error) => {
           console.error('File traitement error:', error);
           return throwError(() => error);
         })
       );
     }else{

      return this.http.post(
        `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/web/ingestion/users`,
        formData,
      )
      .pipe(
        catchError((error) => {
          console.error('File traitement error:', error);
          return throwError(() => error);
        })
      )
     }
  }

  downloadFile(fileName : string): Observable<any> {
    return this.http.get(
      `${REQUESTS_BASE_URL}/api/file-manager/client-api/v1/downloadFile/${fileName}/webIngestion`,
      {
        responseType: 'blob'
      })
    }

    deleteCollaborator(collaboratorId: number){
      return this.http.delete(
        `${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/info/${collaboratorId}`,

      )
    }
}
