<mat-radio-group [(ngModel)]="selectedValue" class="radio-flex">
  <label
    for="{{ item.value }}"
    class="radio-card-wrapper"
    *ngFor="let item of data"
    [ngClass]="
    { 
      'is-checked': item.value === selectedValue, 
      'disabled-card': item.disabled 
    }"
    (click)="item.disabled ? null : onCardClick(item.value)"
  >
    <div class="first-row">
      <div [ngClass]="
        { 
          'first-row-icon': item.value !== selectedValue, 
          'first-row-icon-active': item.value === selectedValue 
        }">
        <mat-icon 
          svgIcon="{{item.value === selectedValue? item.ActiveIcon : item.disabledIcon }}"
        ></mat-icon>
      </div>
      <mat-radio-button
        id="{{ item.value }}"
        [value]="item.value"
        [disabled]="item.value !== selectedValue"
        ><mat-icon class="spec" svgIcon="check"></mat-icon
      ></mat-radio-button>
    </div>
    <div class="second-row">
      <span>{{ item.title }}</span>
      <p>{{ item.description }}</p>
    </div>
  </label>
</mat-radio-group>
