import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, from, Observable, switchMap} from 'rxjs';
import {KeycloakService} from "keycloak-angular";
import {ToasterService} from "../../services/toaster.service";
import {TOASTER_TYPE} from "../../shared/ui-kit/webiat-toaster/webiat-toaster.viewModel";

@Injectable()
export class TokenExpiredInterceptorInterceptor implements HttpInterceptor {

  constructor(private keycloakService: KeycloakService,private toasterService:ToasterService) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleRequest(request, next);
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.keycloakService.shouldUpdateToken(request)) {
      return this.refreshTokenAndRetry(request, next)
    } else {
      return next.handle(request);
    }

  }

  private refreshTokenAndRetry(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.keycloakService.updateToken()).pipe(
      switchMap(() => {
        const newRequest = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.keycloakService.getKeycloakInstance().token
          }
        });
        return next.handle(newRequest);
      }),
      catchError((e) => {
        //check internet connection
        if (e.status === 0) {
          this.toasterService.addToaster(
            true,
            TOASTER_TYPE.ERROR,
            'Veuillez vérifier votre connexion internet',
            5000
          );
        }
        throw e;
      })
    );
  }
}
