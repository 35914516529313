import {Component, Input, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {NgxMaterialTimepickerComponent} from 'ngx-material-timepicker';

@Component({
  selector: 'app-webiat-timepicker',
  templateUrl: './webiat-timepicker.component.html',
  styleUrls: ['./webiat-timepicker.component.scss'],
})
export class WebiatTimepickerComponent {
  @ViewChild('picker') picker!: NgxMaterialTimepickerComponent;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() minTime!: string;
  @Input() maxTime!: string;
  @Input() control: FormControl = new FormControl();

  public time: string = '';
  public isFocused: boolean = false;
  public isPickerOpened: boolean = false;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'clock',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clock.svg')
    );
  }


  public hasError(errorType: string): boolean {
    return (
      this.control?.hasError(errorType) &&
      this.control.touched &&
      !this.isPickerOpened
    );
  }

  public onPickerOpened(): void {
    this.isPickerOpened = true;
  }

  public onPickerClosed(): void {
    this.isPickerOpened = false;
  }

  openTimePicker(): void {
    this.picker.open();
  }
}
