import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { RadioCardViewModel } from 'src/app/shared/ui-kit/webiat-radio-card/webiad-radio-card.viewModel';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-type',
  templateUrl: './notification-type.component.html',
  styleUrls: ['./notification-type.component.scss']
})
export class NotificationTypeComponent implements OnInit {
  parentName: string = "";
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
     private modalService: ModalService,
     private dialogRef: MatDialogRef<NotificationTypeComponent, any>,
     ) {
    iconRegistry.addSvgIcon(
      'standardNotificationIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/standard-notification.svg')
    );
    iconRegistry.addSvgIcon(
      'personalisedNotificationIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/personalized-notification.svg')
    );
    iconRegistry.addSvgIcon(
      'standardNotificationIconDisabled',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/standard-notification-disabled.svg')
    );
    iconRegistry.addSvgIcon(
      'personalisedNotificationIconDisabled',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/personalized-notification-disabled.svg')
    );
    
   iconRegistry.addSvgIcon(
    'proEXpIcon',
    sanitizer.bypassSecurityTrustResourceUrl('assets/icons/proEXpIcon.svg')
  );
  iconRegistry.addSvgIcon(
    'academicExpIcon',
    sanitizer.bypassSecurityTrustResourceUrl('assets/icons/academicExpIcon.svg')
  );
  iconRegistry.addSvgIcon(
    'proEXpIconDisabled',
    sanitizer.bypassSecurityTrustResourceUrl('assets/icons/proEXpIconDisabled.svg')
  );
  iconRegistry.addSvgIcon(
    'academicExpIconDisabled',
    sanitizer.bypassSecurityTrustResourceUrl('assets/icons/academicExpIconDisabled.svg')
  );
  }

  public NotifradioButtons: RadioCardViewModel[] =
    [
      {
        value: "1",
        ActiveIcon: "standardNotificationIcon",
        disabledIcon: "standardNotificationIconDisabled",
        title: "Notification standardisée",
        description: "Ce modèle de notification concerne les messages standard préétabli fournissant des informations uniformes.",
        disabled: false
      },
      {
        value: "2",
        ActiveIcon: "personalisedNotificationIcon",
        disabledIcon: "personalisedNotificationIconDisabled",
        title: "Notification non standardisée",
        description: "Ce modèle de notification concerne les messages personnalisés pour des rappels, alertes et informations adaptés à vos besoins spécifiques ciblés.",
        disabled: false
      },
    ];

    public ExpradioButtons: RadioCardViewModel[] =
    [
      {
        value: "1",
        ActiveIcon: "proEXpIcon",
        disabledIcon: "proEXpIconDisabled",
        title: "Expérience Professionnelle",
        description: "Ajouter une nouvelle expérience professionnelle.",
        disabled: false
      },
      {
        value: "2",
        ActiveIcon: "academicExpIcon",
        disabledIcon: "academicExpIconDisabled",
        title: "Formation Académique",
        description: "Ajouter une formation académique.",
        disabled: false
      },
    ];

  ngOnInit(): void {
    this.parentName = this.modalService.getParentName();
    this.dialogRef.updateSize('664px')
  }

  onSelectedValueChanged(value: string): void {
    this.modalService.setSelectedNotifType(value);
    if (value === '1' || value === '2') {
      this.modalService.setConfirmButtonDisabled(false);
    }
    else {
      this.modalService.setConfirmButtonDisabled(true);
    }
  }

}
