import { FileViewModel } from "../shared/ui-kit/webiat-file-upload/webiat-file-upload.viewModel";

export class ModuleUtil {
    static getMediaFileName(name : string): string{
        return name.split('/').pop() ?? ''
    }


    static parseDate(dateString: string): Date {
        let parts = dateString.split(/\/|\s|:/);
        return new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
    }

    static createFileViewModel(file: File,e: ProgressEvent, mediaType?: string): FileViewModel {
        const fileViewModel: FileViewModel = {
          file: file,
          url: (e.target as FileReader).result,
          progress: Math.round((100 / e.total) * e.loaded) || 100,
          mediaType: mediaType,
        };
        return fileViewModel;
    }
}