<div class="dashboard-content-container">
  <div class="title"><h1>Bonjour {{agentFirstName}} </h1> <mat-icon svgIcon="handEmoji"></mat-icon></div>

  <h4>Bienvenue dans l'interface Backoffice WeBIAT !</h4>
  <p>
    Vous êtes maintenant en charge du contenu qui sera diffusé sur notre
    application dans la section d'accueil. Personnalisez, planifiez et publiez
    des informations cruciales pour notre équipe. Faites en sorte que chaque
    publication contribue à informer, inspirer et renforcer notre communauté.
  </p>
</div>
