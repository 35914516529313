import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ToasterService} from 'src/app/services/toaster.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {TOASTER_TYPE} from '../webiat-toaster/webiat-toaster.viewModel';
import {PublicationsService} from 'src/app/services/publications.service';
import {CommonModule} from '@angular/common';
import {catchError} from "rxjs";

@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CommonModule
  ],
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {

  @Output() publicationDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  dialogRef !: MatDialogRef<DeleteModalComponent>;
  public loading: boolean = false;

  constructor(
    private publicationService: PublicationsService,
    private toasterService: ToasterService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }


  public confirmDelete(): void {
    this.confirmDeleted.emit(true)
    const payload = this.data.pubId;
    if (payload) {
      this.loading = true;
      this.publicationService.deletePublicationById(payload)
        .pipe(
          catchError((error) => {
            this.loading = false;
            this.dialog.closeAll();
            this.router.navigate(['/content-managment'])
            const msgErrorRequest: string = 'Échec de suppression. Veuillez réessayer ultérieurement.';
            this.toasterService.addToaster(true, TOASTER_TYPE.ERROR, msgErrorRequest, 5000);
            return error;
          })
        )
        .subscribe(
          (response:any) => {
            if (response?.status === 200) {
              this.loading = false;
              this.dialog.closeAll();
              const msgSuccessRequest: string = 'Votre publication a été supprimée avec succès.';
              this.toasterService.addToaster(true, TOASTER_TYPE.SUCCESS, msgSuccessRequest, 5000);
              this.publicationDeleted.emit(true);
            }
          }
        );
    } else {
      this.publicationDeleted.emit(true);
      this.dialog.closeAll();
    }


  }

}
