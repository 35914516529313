import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {KeycloakService} from 'keycloak-angular';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {UserService} from 'src/app/services/user.service';
import {WebiatLogoutComponent} from '../webiat-logout/webiat-logout.component';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {NavigationService} from 'src/app/services/navigation.service';
import {filter} from 'rxjs';
import { CommentsService } from 'src/app/services/comments.service';

export const ICON_LIST = [
  { name : 'switchIcon' , path :'assets/icons/switch-icons.svg'},
  { name : 'posts' , path :'assets/icons/posts.svg'},
  { name : 'users' , path :'assets/icons/users.svg'},
  { name : 'users-active' , path :'assets/icons/users-active.svg'},
  { name : 'comments-active' , path :'assets/icons/comments-active-icon.svg'},
  { name : 'comments' , path :'assets/icons/comments-icon.svg'},
  { name : 'postsGrey' , path :'assets/icons/posts-grey.svg'},
  { name : 'logout' , path :'assets/icons/logout.svg'},
]
interface NavItem {
  name: string;
  routerLink: string;
  icon: string;
  activeIcon: string;
}

@Component({
  selector: 'app-webiat-drawer',
  templateUrl: './webiat-drawer.component.html',
  styleUrls: ['./webiat-drawer.component.scss'],
})
export class WebiatDrawerComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public readonly navList: NavItem[] = [
    { name: 'Gestion de contenu', routerLink: 'content-managment', icon: 'postsGrey', activeIcon: 'posts' },
    { name: 'Gestion des collaborateurs', routerLink: 'collaborator-list', icon: 'users', activeIcon: 'users-active' },
    { name: 'Commentaires', routerLink: 'comments', icon: 'comments', activeIcon: 'comments-active' },
  ];
  public agentFirstName!: string;
  public agentLastName!: string;
  public role!: string;
  public imageUrl!: string;
  private id!: string;
  public showButtonLogout: boolean = false;

  url !:string 
  count !:number

  initials: string = '';
  backgroundColor: string = '';
  letterColor: string = '';
  private listBackgroundColors = [
    "#E0EEF5", "#E0E9F5", "#FBEFDB", "#FAE5DB", "#FDF5D9", "#EDEAE9"
  ];
  private listLetterColors = [
    "#2B6788", "#254676", "#BB8217", "#833711", "#AD8D0B", "#5A5149"
  ];
  public firstName!: string;
  public lastName!: string;

  constructor(
    private userService: UserService,
    private keycloakAngular: KeycloakService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public navService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commentService : CommentsService
  ) {

    ICON_LIST.forEach( elt =>{
      this.iconRegistry.addSvgIcon( elt.name , this.sanitizer.bypassSecurityTrustResourceUrl(elt.path))
    })
    
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
      ).subscribe((res : any) =>{      
      this.getCountNotVerifiedComments()
    })
    
  }

  async ngOnInit(): Promise<void> {
    const isAuthenticated = await this.keycloakAngular.isLoggedIn();
    if (isAuthenticated) {
      // Get the Keycloak instance
      const keycloakInstance = this.keycloakAngular.getKeycloakInstance();

      // Check if idTokenParsed is defined
      if (keycloakInstance?.idTokenParsed) {

        this.keycloakAngular.loadUserProfile().then(profile => {
          if (profile?.firstName) {
            this.agentFirstName = profile?.firstName;
          }
          if (profile?.lastName) {
            this.agentLastName = profile?.lastName;
          }

        })
      }
    }

    this.userService
      .getUserInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dataSource) => {
        this.role = dataSource['role']['name'];
        this.id = dataSource['externalId'];
        this.firstName = dataSource['firstname'];
        this.lastName = dataSource['lastname'];
        this.userService
          .getUserPhoto(this.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((imageBlob: Blob) => {
            this.imageUrl = URL.createObjectURL(imageBlob);
            
          },
          (error) => {
            console.error('Erreur lors du chargement de l\'image : ', error);
            this.imageUrl = ''; 
            this.setInitialsAndColors();
          });
      });

    this.setActiveNavItem();

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setActiveNavItem();
    });
  }

  private setActiveNavItem(): void {
    const currentRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path;
    const activeNavItem = this.navList.find(item => item.routerLink === currentRoute);
    if (activeNavItem) {
      this.navService.setActiveItem(activeNavItem.routerLink);
    }
  }

  public navigateTo(routerLink: string): void {
    this.navService.setActiveItem(routerLink);
    this.router.navigateByUrl(routerLink);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showLogoutBtn(): void {
    this.showButtonLogout = !this.showButtonLogout;
  }

  public openDialog(): void {
     this.dialog.open(WebiatLogoutComponent, {
      width: '400px',
    });
  }

  @HostListener('document:click', ['$event.target']) onMouseClick(
    targetElement: any
  ) {
    const logoutWrapperClicked = document
      .getElementById('logoutWrapper')
      ?.contains(targetElement);
    const profileCardClicked = document
      .getElementById('profileCard')
      ?.contains(targetElement);
    if (!logoutWrapperClicked && !profileCardClicked) {
      this.showButtonLogout = false;
    }
  }

  getCountNotVerifiedComments(){
    this.commentService.getCountNotVerifiedComments().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(res =>{
      this.count = res
    })
  }
  
  setInitialsAndColors() {
    if (this.firstName && this.lastName) {
      this.initials = `${this.firstName[0]?.toUpperCase()}${this.lastName[0]?.toUpperCase()}`;
      const colorIndex = (this.calculateAsciiSum(this.firstName) + this.calculateAsciiSum(this.lastName)) % 6;
      this.backgroundColor = this.listBackgroundColors[colorIndex];
      this.letterColor = this.listLetterColors[colorIndex];
    }
  }

  calculateAsciiSum(name: string): number {
    return name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  }
}
