import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'durationSince'
})
export class DurationSincePipe implements PipeTransform {

  transform(dateValue: string, ...args: unknown[]): string {

    return this.timeSince(new Date(dateValue))
  }

  timeSince(date : Date ) {
    let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + " a";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " m";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " j";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " h";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " min";
    }
    return Math.floor(seconds) + " sec";
  }
}
