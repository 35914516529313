import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {EDITOR_CONFIG} from './kolkov-config';
import {fromEvent, merge, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextEditorComponent implements OnInit, OnDestroy,OnChanges {
  @Input() limitCharacterNumber: number = 1000;
  @Input() stylingClass: string = '';
  @Input() defaultText?: string
  @Input() limitText: string = 'caractères';
  @ViewChild('refEditor') editorContainerRef!: ElementRef<HTMLElement>;
  editorConfig: AngularEditorConfig = EDITOR_CONFIG;
  result: string = '';

  @Input() inputText !: FormControl
  @Output() _outputText = new EventEmitter<string>();

  form = new FormGroup({
    editorContent: new FormControl(),
  });
  contenteditable: boolean = true;
  textLength: number = 0;

  keyDowns$ !: Observable<any>
  keyUps$ !: Observable<any>
  keyActions$ !: Observable<any>
  keyBoardSubscription$ !: Subscription
  formSubscription$!: Subscription


  ngOnChanges(){
    if(this.inputText?.value !=''){
      this.form.patchValue(
        {
          editorContent: this.inputText?.value
        }
      )

      this._outputText.emit(this.inputText?.value)
      this.textLength = this.removeHtmlTagsEntities(this.inputText?.value)?.length
    }
  }

  public handleKeyDown(event: KeyboardEvent): void {
    if (
      event.target instanceof HTMLElement &&
      event?.target?.textContent!.length >= this.limitCharacterNumber &&
      this.isPrintableCharacter(event)
    ) {
      event.preventDefault();
    }
  }

  public handlePaste(event: any): void {
    const pasteData = event.clipboardData?.getData('text/plain');
    if (
      pasteData &&
      pasteData.length + event?.target?.textContent!.length >=
      this.limitCharacterNumber
    ) {
      event.preventDefault();
      const availableLength = this.limitCharacterNumber - event?.target?.textContent!.length;
      const adjustedText = pasteData.substring(0, availableLength);
      // get current cursor position
      const selection = window.getSelection();
      //update the old text with the new text
      const newText = event?.target?.textContent!.substring(0, selection?.anchorOffset) + adjustedText + event?.target?.textContent!.substring(selection?.anchorOffset);
      // set the new text
      this.form.get('editorContent')?.setValue(newText);
    }
  }

  private isPrintableCharacter(event: KeyboardEvent): boolean {
    const key = event.key;
    return key.length === 1;
  }

  ngOnInit(): void {
    this.formSubscription$ = this.form.valueChanges.subscribe((res) => {
      this.result = res?.editorContent;
      this._outputText.emit(this.result);
      this.textLength =  this.getTextLengthWithoutHtml(this.result);
      this.inputText?.setValue(this.result)
    });
    if (this.defaultText) {
      this.form.get('editorContent')?.setValue(this.defaultText);
    }
  }

  ngOnDestroy(): void {
    this.keyBoardSubscription$?.unsubscribe();
    this.formSubscription$.unsubscribe();
  }

  getTextLengthWithoutHtml(html: string): number {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent ?? div.innerText ?? '';
    return text.length;
  }
  public resetTextEditor(): void{
    const editorControl = this.form.get('editorContent') as FormControl;
    if (editorControl) {
      editorControl.setValue('');
    }
  }


  removeHtmlTagsEntities(textWithHtmlTags: string): string {
    let removedHtmlTag = textWithHtmlTags?.replace(/<[^>]+>/g, '');
    let replacedHtmlEntities = removedHtmlTag?.replace(/&[a-zA-Z]+;/g, '_');
    return replacedHtmlEntities;
  }

}
