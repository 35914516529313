import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEditorComponent } from './text-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditorDirective } from 'src/app/directive/editor.directive';

@NgModule({
  declarations: [
    TextEditorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularEditorModule,   
    EditorDirective 
  ],
  exports:[
    TextEditorComponent
  ]
})
export class TextEditorModule { }
