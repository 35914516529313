import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {Observable} from 'rxjs/internal/Observable';
import {REQUESTS_BASE_URL} from 'src/environments/environment';
import {UserInfoPayload} from "../shared/viewModels/collaborator.viewModel";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public agentFirstName!: string;
  public agentLastName!: string;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  public getUserInfo(): Observable<any> {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/info`
    );
  }

  public getUserInfoById(id: string): Observable<any> {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/info/${id}`,
      {
        observe: 'response'
      });
  }

  public getUserPhoto(id: string): Observable<any> {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/content-service/client-api/files/pictures?name=${id}&category=profileHD`, {
      responseType: 'blob' as 'json'
    });
  }

  public updateUserinfo(data: UserInfoPayload): Observable<any> {
    return this.http.put<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/update-info`, data,
      {
        observe: 'response'
      });
  }

  private getUserProfile() {
    this.keycloakService.loadUserProfile().then(profile => {
      if (profile?.firstName) {
        this.agentFirstName = profile?.firstName!;
      }
      if (profile?.lastName) {
        this.agentLastName = profile?.lastName!;
      }

    })
  }

  public updateAboutUserinfo(body: any): Observable<any> {
    console.log(body)
    return this.http.put<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/update-professional-info`, body,
      {
        observe: 'response'
      });
  }
}
