import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  authToken !: string
  constructor(private kcservice: KeycloakService) {
    
    this.kcservice.getToken().then(res => {
      this.authToken = res       
    })
    
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(this.authToken){
      let modifiedRequest = request.clone({
        setHeaders :{
          Authorization: `Bearer `+ this.authToken,
        }
      })
      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }
}

