<div class="select-wrapper">
  <label *ngIf="label">{{ label }}</label>
  
  <select class="select-content" 
    [formControl]="control" 
    [ngClass]="{'has-error' : hasError('required')}">
    
    <option  value="" disabled selected hidden>{{ label }}</option>
    <option *ngFor="let item of data" [ngValue]="item.id">
      <span class="content">{{ item.name }}</span>
    </option>
  </select>
  <span *ngIf="hasError('required')" class="error-message">
    Ce champ est requis*
  </span>
</div>
