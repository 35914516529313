import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FileViewModel } from '../webiat-file-upload/webiat-file-upload.viewModel';
import { Observable, interval, map, startWith } from 'rxjs';
import {iconCategoryList} from 'src/environments/globalVariable';
import { CommonModule } from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';




@Component({
  selector: 'app-preview-news-content',
  standalone:true,
  templateUrl: './preview-news-content.component.html',
  styleUrls: ['./preview-news-content.component.scss'],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,

  ]
  
})
export class PreviewNewsContentComponent implements OnChanges {

  @Input() uploadedFile : Array<FileViewModel>= [];
  @Input() reactionStatus !: boolean;
  @Input() formValue!: any;

  @Input() displayLoading : boolean = false 
  @Input() isLoadingDetails !:boolean 
  @Input() isLoadingMedia !:boolean 

  imageIsExist : boolean = false;
  videoIsExist : boolean = false;
  docIsExist : boolean = false;

  imageList : Array<FileViewModel>= [];
  videoList : Array<FileViewModel>= [];
  docList : Array<FileViewModel>= [];

  categoryTitle! : string

  currentTime$ : Observable<Date> = interval(1000).pipe(
    startWith(0),
    map(() => new Date() )
  )

  iconNameImage: string = '';



  ngOnChanges(){
    this.initialzeEmptyPreviewDataFile()
    
    this.checkUploadedFilesContentType(this.uploadedFile);

    this.imageList = this.uploadedFile?.filter(element =>{
      return element.file.type.includes("image")
    })

    this.videoList =  this.uploadedFile?.filter(element =>{
      return element.file.type.includes("video")
    })

    this.docList = this.uploadedFile?.filter(element =>{
      return (!element.file.type.includes("video") && !element.file.type.includes("image") )
    })

    this.iconNameImage = this.getIconImageName(this.formValue?.category)

    this.categoryTitle = this.getCategoryName(this.formValue?.category)

  }


  checkUploadedFilesContentType( uploadedFiles : Array<FileViewModel>){    
    if(uploadedFiles?.length !=0 ){
      this.imageIsExist= uploadedFiles?.some(element =>element.file.type.includes("image") )
      this.videoIsExist= uploadedFiles?.some(element =>element.file.type.includes("video") )
      this.docIsExist= uploadedFiles?.some(element => !element.file.type.includes("video") && !element.file.type.includes("image") )
    }
  }

  initialzeEmptyPreviewDataFile(){
    this.imageIsExist  = false;
    this.videoIsExist  = false;
    this.docIsExist  = false;
  }

  getIconImageName(categoryID : string ):string{
    return iconCategoryList.filter(elt=> {
      return elt.id == categoryID
    })[0]?.name ?? ''
  }

  getCategoryName(categoryID : string):string{
    return iconCategoryList.filter(elt=> {
      return elt.id == categoryID
    })[0]?.category ?? 'Titre de la catégorie'
  }

   formatBytes(bytes : number ,decimals? : number ) {
    if(bytes == 0) return '0 Bytes';
    let k = 1024,
        dm = decimals ?? 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }

  getFileNameType(file : string){
    return file.split(".")
  }

  formatDateString(dateString: string): string {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    const formatDate: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('fr-FR', formatDate);
  }

}
