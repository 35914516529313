import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[editorDirective]',
  standalone: true
})
export class EditorDirective  {

  
  constructor(private el : ElementRef<HTMLElement> , private renderer : Renderer2) {

  }

  @HostListener('keydown', ['$event']) onKeyDown(event : KeyboardEvent){
    if(event.code === "Backspace"){
         let element : ElementRef<HTMLElement> | any  = this.el.nativeElement.children.item(0)?.children.item(1)?.children.item(0)
      this.renderer.setAttribute(element , 'contenteditable', 'true')
    }
  }
}
