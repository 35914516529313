import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ExceededData, FileViewModel } from './webiat-file-upload.viewModel';
import { ModalService } from 'src/app/services/modal.service';
import { Subject } from 'rxjs';

export const ICON_LIST = [
  { name : 'fileUploader' , path :'assets/icons/file-uploader.svg'},
  { name : 'fileUploaderError' , path :'assets/icons/file-uploader-error.svg'},
  { name : 'trash' , path :'assets/icons/trash.svg'},
  { name : 'trashOutline' , path :'assets/icons/trashOutline.svg'},
  { name : 'check' , path :'assets/icons/check.svg'},
]
@Component({
  selector: 'app-webiat-file-upload',
  templateUrl: './webiat-file-upload.component.html',
  styleUrls: ['./webiat-file-upload.component.scss'],
})
export class WebiatFileUploadComponent implements OnInit, OnChanges , OnDestroy {
  @Input() DocVideoFiles !: any
  @Input() ImageFiles !: any 
  @Input() isNewCard : boolean = true

  @Input() filesType!: string;
  @Input() isTreaty!: boolean;
  @Input() isTreatCompleted!: boolean;
  @Input() multiple !: boolean
  @Output() limitExceeded = new EventEmitter<ExceededData>();
  @Output() imageTypeError = new EventEmitter<boolean>();
  @Output() fileCombinationViolation = new EventEmitter<string[]>();
  @Output() fileChanges: EventEmitter<FileViewModel[]> = new EventEmitter();
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() resetArraysEvent = new EventEmitter<void>();
  @Output() getFileResult = new EventEmitter<boolean>();

  public isShown: boolean = true;
  public selectedImageFiles: FileViewModel[] = [];
  public selectedDocVideoFiles: FileViewModel[] = [];
  public selectedIndex: number = -1;
  public isWithin: boolean = true;
  public isAuthorized: boolean = true;
  public isUploaded: boolean = false;
  public selectedFile: any;
  public radioButtons: any;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    ) {
    ICON_LIST.forEach( elt =>{
      this.iconRegistry.addSvgIcon( elt.name , this.sanitizer.bypassSecurityTrustResourceUrl(elt.path))
    })
  }

  ngOnChanges(): void { 
    if(!this.isNewCard){
      this.selectedDocVideoFiles = this.DocVideoFiles ?? []
      this.selectedImageFiles = this.ImageFiles ?? []
      this.handleFilesCombination()
      this.handleFilesChange()
    }
  }


  ngOnInit(): void {
  }

  public getAcceptType(): string {
    switch (this.filesType) {
      case 'image':
        return 'image/png, image/jpeg';
      case 'video':
        return 'video/*';
      case 'doc':
        return '.pdf, .doc, .docx';
      case 'ingest':
        return '.txt, .csv'
      default:
        return '';
    }
  }

  public onSelectFiles($event: Event): void {
    const fileArray = Array.from(($event.target as HTMLInputElement)?.files as FileList);

    for (const file of fileArray) {
      if (file.size === 0) {
        continue;
      }
      if (!this.isFileAlreadySelected(file)) {
        const reader: FileReader = new FileReader();
        const fileType: string = file.type.split('/')[0];
        const extension: string = file.name.split('.').pop() as string;

          reader.onload = (e: ProgressEvent) => {
            switch (fileType) {
              case 'image':
                if (
                  this.isWithinLimit(fileType) &&
                  this.isImageTypeAuthorized(extension) &&
                  fileType === this.filesType
                ) {
                  const fileViewModel: FileViewModel = this.createFileViewModel(file,e,'PHOTO');
                  this.selectedImageFiles.push(fileViewModel);
                }
                break;
              case 'video':
                if (
                  this.isWithinLimit(fileType) &&
                  fileType === this.filesType
                ) {
                  const fileViewModel: FileViewModel = this.createFileViewModel(file,e,'VIDEO');
                  this.selectedDocVideoFiles.push(fileViewModel);
                }
                break;
              case 'application':
                if (
                  this.isWithinLimit(fileType) &&
                  this.isDocTypeAuthorized(extension) &&
                  this.filesType === 'doc'
                ) {
                  const fileViewModel: FileViewModel = this.createFileViewModel(file,e);
                  this.selectedDocVideoFiles.push(fileViewModel);
                  }
                break;
                case 'text':
                if(
                  this.isWithinLimit(fileType) &&
                  this.isIngestTypeAuthorized(extension) &&
                  this.filesType === 'ingest'
                ){
                  const fileViewModel: FileViewModel = this.createFileViewModel(file,e);
                  this.selectedDocVideoFiles.push(fileViewModel);
                  this.selectedFile = fileViewModel;
                  this.isShown = false;
                  this.modalService.updateModalSize( '480px','268px');
                  this.isUploaded =true;
                  this.getFileResult.emit(this.isUploaded);
                }
                break;
              default:
                break;
            }

          this.handleFilesCombination();
          this.resetFileInput();
          // Emit the file changes to the parent component
          this.handleFilesChange();
          this.handleFileChange();
        };
        reader.readAsDataURL(file);
      }
    }
  }

  truncateFileName(fileName: any, maxLength: number): string {
    return fileName.length <= maxLength ? fileName : fileName.substr(0, maxLength - 3) + '...'
  }
  
  updateModalSize() {
    this.modalService.updateModalSize( '480px','268px'); // Nouvelles dimensions de la fenêtre modale
  }

  private createFileViewModel(file: File,e: ProgressEvent, mediaType?: string): FileViewModel {
    const fileViewModel: FileViewModel = {
      file: file,
      url: (e.target as FileReader).result,
      progress: Math.round((100 / e.total) * e.loaded) || 100,
      mediaType: mediaType,
    };
    return fileViewModel;
  }

  private isFileAlreadySelected(file: File): boolean {
    return (
      [...this.selectedImageFiles,...this.selectedDocVideoFiles].some((selectedFile) => selectedFile.file.name === file.name)
    );
  }

  private resetFileInput(): void {
    const fileInput: any = document.getElementById('fileDropRef');
    if (fileInput) {
      fileInput.value = '';
    }
  }

  public removeFile(index: number, isImage: boolean): void {
    this.isShown = true;
    this.isUploaded = false;
    this.isTreatCompleted = false;
    this.isTreaty = false;
    this.getFileResult.emit(this.isUploaded);
    this.modalService.updateModalSize('480px', '322px');
    if (index !== -1) {
      isImage ?  this.selectedImageFiles.splice(index, 1) : this.selectedDocVideoFiles.splice(index, 1)
      this.selectedIndex = -1;
      this.isWithin = true;
      this.isAuthorized = true;
      this.handleFilesCombination();
      this.resetFileInput();
      this.handleFilesChange();
      this.handleFileChange();
    }
  }

  public clickOnImage(index: number): void {
    this.selectedIndex = this.selectedIndex !== index ? index : -1
  }

  public formatOctets(octet: number, decimals?: number): string {
    if (octet === 0) {
      return '0 Octet';
    }
    const k = 1024;
    const dm = decimals! <= 0 ? 0 : decimals || 2;
    const sizes = ['Octet', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
    const i = Math.floor(Math.log(octet) / Math.log(k));
    return parseFloat((octet / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public isImage(fileType: string): boolean {
    return fileType === 'image';
  }

  private isWithinLimit(fileType: string): boolean {
    switch (fileType) {
      case 'image':
        this.isWithin = this.selectedImageFiles.length + 1 <= 20;
        break;
      case 'application':
        this.isWithin = this.selectedDocVideoFiles.filter(
          (selectedFile) => selectedFile.file.type.startsWith('application')
          ).length + 1 <= 2;
        break;
      case 'video':
        this.isWithin = this.selectedDocVideoFiles.filter(
            (selectedFile) => selectedFile.file.type.startsWith('video')
          ).length + 1 <= 1;
        break;
      default:
        break;
    }
    this.limitExceeded.emit({exceeded: !this.isWithin, type: fileType});
    return this.isWithin;
  }

  private isImageTypeAuthorized(extension: string): boolean {
    const imageTypes: string[] = ['jpeg', 'jpg', 'png'];
    this.isAuthorized = imageTypes.includes(extension);
    this.imageTypeError.emit(!this.isAuthorized);
    return this.isAuthorized;
  }

  private isDocTypeAuthorized(extension: string): boolean {
    const docTypes: string[] = ['pdf', 'doc', 'docx', 'txt'];
    return docTypes.includes(extension);
  }

  private isIngestTypeAuthorized(extension: string): boolean {
    const documentTypes: string[] = ['txt', 'csv'];
    return documentTypes.includes(extension);
  }

  private countSelectedFilesByType(): {
    images: number;
    videos: number;
    documents: number;
  } {
    let images: number = 0;
    let videos: number = 0;
    let documents: number = 0;

    images = this.selectedImageFiles.length;

    for (const selectedFile of this.selectedDocVideoFiles) {
      selectedFile.file.type.startsWith('video') ? videos++ : documents++
    }
    return {images, videos, documents};
  }

  private handleFilesCombination(): void {
    const {images, videos, documents} = this.countSelectedFilesByType();
    switch (true) {
      case videos === 1 && images === 0 && documents < 2:
        this.fileCombinationViolation.emit(['video', 'image']);
        break;
      case images > 0 && videos === 0 && documents < 2:
        this.fileCombinationViolation.emit(['video']);
        break;
      case documents >= 2 && videos === 1 && images === 0:
        this.fileCombinationViolation.emit(['doc', 'video', 'image']);
        break;
      case documents >= 2 && videos < 1 && images > 0:
        this.fileCombinationViolation.emit(['doc', 'video']);
        break;
      case documents >= 2:
        this.fileCombinationViolation.emit(['doc']);
        break;
      default:
        this.fileCombinationViolation.emit([]);
        break;
    }
  }

  public handleFilesChange(): void {
    const mergedArray = [
      ...this.selectedImageFiles,
      ...this.selectedDocVideoFiles,
    ];
    this.fileChanges.emit(mergedArray);
  }


  public handleFileChange(): void {
    const file = this.selectedDocVideoFiles[0]?.file;
    this.fileChange.emit(file);
  }

  public resetArrays(): void {
    this.selectedImageFiles = this.selectedDocVideoFiles = [];
    this.fileChanges.emit();
    this.resetArraysEvent.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
