import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {KeycloakService} from 'keycloak-angular';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-webiat-logout',
  templateUrl: './webiat-logout.component.html',
  styleUrls: ['./webiat-logout.component.scss'],
})
export class WebiatLogoutComponent {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<WebiatLogoutComponent>,
    private keycloakAngular: KeycloakService
  ) {
    iconRegistry.addSvgIcon(
      'orangeLogout',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/orange-logout.svg')
    );
  }


  public logOut(): void {
    const redirectUri: string = environment.REDIRECTION_URI;
    this.keycloakAngular.logout(redirectUri);
    this.closeDialog();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
