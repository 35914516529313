import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'colorEdit',
  standalone: true
})
export class ColorEditPipe implements PipeTransform {

  constructor(protected _sanitizer: DomSanitizer) {}
  transform(items: string): SafeHtml{
    // Sanitize the HTML content using Angular's DomSanitizer
    const sanitizedHtml = this._sanitizer.sanitize(SecurityContext.HTML, items);
    // Return the sanitized HTML content
    return sanitizedHtml || '';
  }

}
