<div class="drawer-container">
  <div class="drawer-content-top">
    <div class="drawer-logo">
      <img class="logo app-icon" src="assets/images/AppIcon.png" alt="" />
      <img class="logo app-name" src="assets/images/AppName.png" alt="" />
    </div>
    <mat-list class="drawer-nav-list">
      <mat-list-item
        class="drawer-nav-item"
        *ngFor="let item of navList"
        routerLinkActive="active-item"
        (click)="navigateTo(item.routerLink)"
        [ngClass]="{
          active: (navService.getActiveItem() | async) === item.routerLink
        }"
      >
        <div class="inner">
          <mat-icon [svgIcon]="(navService.getActiveItem() | async) === item.routerLink ? item.activeIcon : item.icon"></mat-icon>
          <div matListItemTitle>{{ item.name }}</div>
        </div>

        <span *ngIf="item.routerLink !='comments' || count == 0" class="dot"></span>
        <span *ngIf="item.routerLink =='comments' && count!= 0" class="counter">{{count}}</span>
      </mat-list-item>

    </mat-list>
  </div>
  <div class="drawer-content-bottom">
    <mat-card class="profile-card" id="profileCard">
      <div *ngIf="imageUrl === ''" [ngStyle]="{'background-color': backgroundColor}" class="card-avatar initials">
        <span [ngStyle]="{'color': letterColor}">{{ initials }}</span>
      </div>
      <mat-card-header>
        <div *ngIf="imageUrl"
          mat-card-avatar
          class="card-avatar"
          [style.background-image]="'url(' + imageUrl + ')'"
        ></div>
        <mat-card-title
          >{{ agentFirstName }} {{ agentLastName }}</mat-card-title
        >
        <mat-card-subtitle>{{ role }}</mat-card-subtitle>
        <div style="flex-grow: 1"></div>
        <button mat-icon-button (click)="showLogoutBtn()">
          <mat-icon svgIcon="switchIcon"></mat-icon>
        </button>
      </mat-card-header>
    </mat-card>

    <div class="logout-btn-wrapper" id="logoutWrapper" *ngIf="showButtonLogout">
      <button mat-button class="logout-button" (click)="openDialog()">
        <mat-icon svgIcon="logout"></mat-icon>
        Déconnexion
      </button>
    </div>
  </div>
</div>
